import { injectable, inject } from "inversify";
import GenosetListScreenController from "@/adapters/controllers/screen-genoset-list";
import { IGenosetGateway } from "@/adapters/gateways/dna";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class InitGenosetListInteractor {
  _genosetListScreenController: GenosetListScreenController;
  _genosetGateway: IGenosetGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(GenosetListScreenController)
    genosetListScreenController: GenosetListScreenController,
    @inject("GenosetGateway")
    genosetGateway: IGenosetGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._genosetListScreenController = genosetListScreenController;
    this._genosetGateway = genosetGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._genosetListScreenController.setAsLoading!();
    const genosetsPage = await this._genosetGateway.list(session.tokens!);
    this._genosetListScreenController.setData!({ genosetsPage });
    this._genosetListScreenController.setAsLoaded!();
  }
}
