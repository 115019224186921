import { injectable, inject } from "inversify";
import OverviewScreenController from "@/adapters/controllers/screen-overview";
import { RouterController } from "@/adapters/controllers/router";
import { ISessionGateway } from "@/adapters/gateways/users";
import { IAdministrationGateway } from "@/adapters/gateways/administration";

@injectable()
export default class InitOverviewInteractor {
  _overviewScreenController: OverviewScreenController;
  _administrationGateway: IAdministrationGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(OverviewScreenController)
    overviewScreenController: OverviewScreenController,
    @inject("AdministrationGateway")
    administrationGateway: IAdministrationGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._overviewScreenController = overviewScreenController;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
    this._administrationGateway = administrationGateway;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._overviewScreenController.setAsLoading!();
    const dashboard = await this._administrationGateway.dashboard(
      session.tokens
    );
    this._overviewScreenController.setData!({ dashboard });
    this._overviewScreenController.setAsLoaded!();
  }
}
