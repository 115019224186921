import { injectable, inject } from "inversify";
import SlotCreateScreenController from "@/adapters/controllers/screen-slot-create";
import { ISlotGateway } from "@/adapters/gateways/slots";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { Slot } from "@/domain/slots/models";

@injectable()
export default class SubmitFormInteractor {
  _slotCreateScreenController: SlotCreateScreenController;
  _slotGateway: ISlotGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(SlotCreateScreenController)
    slotCreateScreenController: SlotCreateScreenController,
    @inject("SlotGateway")
    slotGateway: ISlotGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._slotCreateScreenController = slotCreateScreenController;
    this._slotGateway = slotGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(slot: Slot): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._slotCreateScreenController.setAsLoading!();
    await this._slotGateway.create(slot, session.tokens!);
    this._slotCreateScreenController.setAsLoaded!();
    this._routerController.pushSlotList!();
  }
}
