import { RouterController } from "@/adapters/controllers/router";
import ResearchDetailsScreenController from "@/adapters/controllers/screen-research-details";
import { IResearchGateway } from "@/adapters/gateways/researches";
import { ISessionGateway } from "@/adapters/gateways/users";
import { inject, injectable } from "inversify";

@injectable()
export default class SelectResearchProcessInteractor {
  _researchDetailsScreenController: ResearchDetailsScreenController;
  _researchGateway: IResearchGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(ResearchDetailsScreenController)
    researchDetailsScreenController: ResearchDetailsScreenController,
    @inject("ResearchGateway")
    researchGateway: IResearchGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._researchDetailsScreenController = researchDetailsScreenController;
    this._researchGateway = researchGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(id: string): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._researchDetailsScreenController.setAsLoading!();
    const data = this._researchDetailsScreenController.getData!();
    await this._researchGateway.process(id, session.tokens);
    data.research = await this._researchGateway.retrieve(id, session.tokens!);
    this._researchDetailsScreenController.setData!(data);
    this._researchDetailsScreenController.setAsLoaded!();
  }
}
