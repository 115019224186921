import { injectable, inject } from "inversify";
import BehaviourCreateScreenController from "@/adapters/controllers/screen-behaviour-create";
import { IBehaviourGateway } from "@/adapters/gateways/behaviours";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { Behaviour } from "@/domain/behaviours/models";

@injectable()
export default class SubmitFormInteractor {
  _behaviourCreateScreenController: BehaviourCreateScreenController;
  _behaviourGateway: IBehaviourGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(BehaviourCreateScreenController)
    behaviourCreateScreenController: BehaviourCreateScreenController,
    @inject("BehaviourGateway")
    behaviourGateway: IBehaviourGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._behaviourCreateScreenController = behaviourCreateScreenController;
    this._behaviourGateway = behaviourGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(behaviour: Behaviour): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._behaviourCreateScreenController.setAsLoading!();
    await this._behaviourGateway.create(behaviour, session.tokens!);
    this._behaviourCreateScreenController.setAsLoaded!();
    this._routerController.pushBehaviourList!();
  }
}
