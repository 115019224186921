import { injectable } from "inversify";
import CustomReportEditScreenData from "@/adapters/models/screen-custom-report-edit";

type EmptyAction = () => void;
type SetData = (data: CustomReportEditScreenData) => void;
type GetData = () => CustomReportEditScreenData;

@injectable()
export default class CustomReportEditScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
