import { injectable, inject } from "inversify";
import CorrelationEditScreenController from "@/adapters/controllers/screen-correlation-edit";
import { ICorrelationGateway } from "@/adapters/gateways/correlations";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class InitCorrelationEditScreenInteractor {
  _correlationEditScreenController: CorrelationEditScreenController;
  _correlationGateway: ICorrelationGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(CorrelationEditScreenController)
    correlationEditScreenController: CorrelationEditScreenController,
    @inject("CorrelationGateway")
    correlationGateway: ICorrelationGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._correlationEditScreenController = correlationEditScreenController;
    this._correlationGateway = correlationGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(id: string): Promise<void> {
    this._correlationEditScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    const correlation = await this._correlationGateway.retrieve(
      session.tokens!,
      id
    );
    this._correlationEditScreenController.setData!({ correlation });
    this._correlationEditScreenController.setAsLoaded!();
  }
}
