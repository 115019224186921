import { injectable } from "inversify";
import RobotListScreenData from "@/adapters/models/screen-robot-list";

type EmptyAction = () => void;
type SetData = (data: RobotListScreenData) => void;
type GetData = () => RobotListScreenData;

@injectable()
export default class RobotListScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
