import { injectable } from "inversify";
import ReportPlusViewScreenData from "@/adapters/models/screen-report-plus-view";

type EmptyAction = () => void;
type SetData = (data: ReportPlusViewScreenData) => void;
type GetData = () => ReportPlusViewScreenData;

@injectable()
export default class ReportPlusViewScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
