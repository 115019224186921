import { injectable, inject } from "inversify";
import GenosetListScreenController from "@/adapters/controllers/screen-genoset-list";
import { Genoset } from "@/domain/dna/models";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class SelectGenosetEditInteractor {
  _routerController: RouterController;

  constructor(
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._routerController = routerController;
  }

  async handle(instance: Genoset): Promise<void> {
    this._routerController.pushGenosetEdit!(instance.id);
  }
}
