import { injectable } from "inversify";
import GenotypeEditScreenData from "@/adapters/models/screen-genotype-edit";

type EmptyAction = () => void;
type SetData = (data: GenotypeEditScreenData) => void;
type GetData = () => GenotypeEditScreenData;

@injectable()
export default class GenotypeEditScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
