import { RouterController } from "@/adapters/controllers/router";
import RobotEditScreenController from "@/adapters/controllers/screen-robot-edit";
import { IRobotGateway } from "@/adapters/gateways/robots";
import { ISessionGateway } from "@/adapters/gateways/users";
import { Robot } from "@/domain/robots/models";
import { inject, injectable } from "inversify";

@injectable()
export default class SubmitFormInteractor {
  _robotEditScreenController: RobotEditScreenController;
  _robotGateway: IRobotGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(RobotEditScreenController)
    robotEditScreenController: RobotEditScreenController,
    @inject("RobotGateway")
    robotGateway: IRobotGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._robotEditScreenController = robotEditScreenController;
    this._robotGateway = robotGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(robot: Robot): Promise<void> {
    this._robotEditScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    await this._robotGateway.update(session.tokens!, robot);
    this._routerController.pushRobotList!();
    this._robotEditScreenController.setAsLoaded!();
  }
}
