import { injectable, inject } from "inversify";
import GenotypeListScreenController from "@/adapters/controllers/screen-genotype-list";
import { IGenotypeGateway } from "@/adapters/gateways/dna";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class ChangeFilterInteractor {
  _genotypeListScreenController: GenotypeListScreenController;
  _genotypeGateway: IGenotypeGateway;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(GenotypeListScreenController)
    genotypeListScreenController: GenotypeListScreenController,
    @inject("GenotypeGateway")
    genotypeGateway: IGenotypeGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._genotypeListScreenController = genotypeListScreenController;
    this._genotypeGateway = genotypeGateway;
    this._sessionGateway = sessionGateway;
  }

  async handle(page: number, search: string, sort: string): Promise<void> {
    this._genotypeListScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    const genotypesPage = await this._genotypeGateway.list(
      session.tokens!,
      page,
      search,
      sort
    );
    this._genotypeListScreenController.setData!({ genotypesPage });
    this._genotypeListScreenController.setAsLoaded!();
  }
}
