import { injectable } from "inversify";
import GenomemosetListScreenData from "@/adapters/models/screen-genomemoset-list";

type EmptyAction = () => void;
type SetData = (data: GenomemosetListScreenData) => void;
type GetData = () => GenomemosetListScreenData;

@injectable()
export default class GenomemosetListScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
