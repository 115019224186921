import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/report-plus-view",
    name: "ReportPlusView",
    component: () => import("@/application/screens/ReportPlusView.vue")
  },
  {
    path: "/report-plus-list",
    name: "ReportPlusList",
    component: () => import("@/application/screens/ReportPlusList.vue")
  },
  {
    path: "/report-plus-edit",
    name: "ReportPlusEdit",
    component: () => import("@/application/screens/ReportPlusEdit.vue")
  },
  {
    path: "/report-plus-create",
    name: "ReportPlusCreate",
    component: () => import("@/application/screens/ReportPlusCreate.vue")
  },
  {
    path: "/snp-frequency-list",
    name: "SnpFrequencyList",
    component: () => import("@/application/screens/SnpFrequencyList.vue")
  },
  // =============================================================================
  // FULL PAGE LAYOUTS
  // =============================================================================
  {
    path: "",
    component: () => import("@/application/layouts/full-page/FullPage.vue"),
    children: [
      // =============================================================================
      // PAGES
      // =============================================================================
      {
        path: "/",
        name: "Login",
        component: () => import("@/application/screens/Login.vue")
      }
    ]
  },
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
    path: "",
    component: () => import("@/application/layouts/main/Main.vue"),
    children: [
      // =============================================================================
      // Theme Routes
      // =============================================================================
      {
        path: "/overview",
        name: "Overview",
        component: () => import("@/application/screens/Overview.vue")
      },
      {
        path: "/report-edit/:id",
        name: "ReportEdit",
        component: () => import("@/application/screens/ReportEdit.vue")
      },
      {
        path: "/report-create",
        name: "ReportCreate",
        component: () => import("@/application/screens/ReportCreate.vue")
      },
      {
        path: "/report-list",
        name: "ReportList",
        component: () => import("@/application/screens/ReportList.vue")
      },
      {
        path: "/behaviour-list",
        name: "BehaviourList",
        component: () => import("@/application/screens/BehaviourList.vue")
      },
      {
        path: "/behaviour-edit/:id",
        name: "BehaviourEdit",
        component: () => import("@/application/screens/BehaviourEdit.vue")
      },
      {
        path: "/behaviour-create",
        name: "BehaviourCreate",
        component: () => import("@/application/screens/BehaviourCreate.vue")
      },
      {
        path: "/genoset-list",
        name: "GenosetList",
        component: () => import("@/application/screens/GenosetList.vue")
      },

      {
        path: "/genoset-edit/:id",
        name: "GenosetEdit",
        component: () => import("@/application/screens/GenosetEdit.vue")
      },
      {
        path: "/genoset-create",
        name: "GenosetCreate",
        component: () => import("@/application/screens/GenosetCreate.vue")
      },
      {
        path: "/genotype-edit/:id",
        name: "GenotypeEdit",
        component: () => import("@/application/screens/GenotypeEdit.vue")
      },
      {
        path: "/genotype-create",
        name: "GenotypeCreate",
        component: () => import("@/application/screens/GenotypeCreate.vue")
      },
      {
        path: "/genotype-list",
        name: "GenotypeList",
        component: () => import("@/application/screens/GenotypeList.vue")
      },
      {
        path: "/genomemoset-edit/:id",
        name: "GenomemosetEdit",
        component: () => import("@/application/screens/GenomemosetEdit.vue")
      },
      {
        path: "/genomemoset-list",
        name: "GenomemosetList",
        component: () => import("@/application/screens/GenomemosetList.vue")
      },
      {
        path: "/genomemoset-create",
        name: "GenomemosetCreate",
        component: () => import("@/application/screens/GenomemosetCreate.vue")
      },
      {
        path: "/snp-create",
        name: "SnpCreate",
        component: () => import("@/application/screens/SnpCreate.vue")
      },
      {
        path: "/snp-edit/:id",
        name: "SnpEdit",
        component: () => import("@/application/screens/SnpEdit.vue")
      },
      {
        path: "/snp-list",
        name: "SnpList",
        component: () => import("@/application/screens/SnpList.vue")
      },
      {
        path: "/plan-edit/:id",
        name: "PlanEdit",
        component: () => import("@/application/screens/PlanEdit.vue")
      },
      {
        path: "/plan-create",
        name: "PlanCreate",
        component: () => import("@/application/screens/PlanCreate.vue")
      },
      {
        path: "/plan-list",
        name: "PlanList",
        component: () => import("@/application/screens/PlanList.vue")
      },
      {
        path: "/user-edit/:id",
        name: "UserEdit",
        component: () => import("@/application/screens/UserEdit.vue")
      },
      {
        path: "/user-create",
        name: "UserCreate",
        component: () => import("@/application/screens/UserCreate.vue")
      },
      {
        path: "/user-list",
        name: "UserList",
        component: () => import("@/application/screens/UserList.vue")
      },
      {
        path: "/research-details/:id",
        name: "ResearchDetails",
        component: () => import("@/application/screens/ResearchDetails.vue")
      },
      {
        path: "/research-edit/:id",
        name: "ResearchEdit",
        component: () => import("@/application/screens/ResearchEdit.vue")
      },
      {
        path: "/research-create",
        name: "ResearchCreate",
        component: () => import("@/application/screens/ResearchCreate.vue")
      },
      {
        path: "/research-list",
        name: "ResearchList",
        component: () => import("@/application/screens/ResearchList.vue")
      },
      {
        path: "/plan-code-create",
        name: "PlanCodeCreate",
        component: () => import("@/application/screens/PlanCodeCreate.vue")
      },
      {
        path: "/plan-code-list",
        name: "PlanCodeList",
        component: () => import("@/application/screens/PlanCodeList.vue")
      },
      {
        path: "/correlation-view/:id",
        name: "CorrelationView",
        component: () => import("@/application/screens/CorrelationView.vue")
      },
      {
        path: "/correlation-edit/:id",
        name: "CorrelationEdit",
        component: () => import("@/application/screens/CorrelationEdit.vue")
      },
      {
        path: "/correlation-create",
        name: "CorrelationCreate",
        component: () => import("@/application/screens/CorrelationCreate.vue")
      },
      {
        path: "/correlation-list",
        name: "CorrelationList",
        component: () => import("@/application/screens/CorrelationList.vue")
      },
      {
        path: "/slot-edit/:id",
        name: "SlotEdit",
        component: () => import("@/application/screens/SlotEdit.vue")
      },
      {
        path: "/slot-create",
        name: "SlotCreate",
        component: () => import("@/application/screens/SlotCreate.vue")
      },
      {
        path: "/slot-list",
        name: "SlotList",
        component: () => import("@/application/screens/SlotList.vue")
      },
      {
        path: "/robot-view/:id",
        name: "RobotView",
        component: () => import("@/application/screens/RobotView.vue")
      },
      {
        path: "/robot-edit/:id",
        name: "RobotEdit",
        component: () => import("@/application/screens/RobotEdit.vue")
      },
      {
        path: "/robot-create",
        name: "RobotCreate",
        component: () => import("@/application/screens/RobotCreate.vue")
      },
      {
        path: "/robot-list",
        name: "RobotList",
        component: () => import("@/application/screens/RobotList.vue")
      },
      {
        path: "/custom-report-list",
        name: "CustomReportList",
        component: () => import("@/application/screens/CustomReportList.vue")
      },
      {
        path: "/custom-report-edit/:id",
        name: "CustomReportEdit",
        component: () => import("@/application/screens/CustomReportEdit.vue")
      },
      {
        path: "/custom-report-view/:id",
        name: "CustomReportView",
        component: () => import("@/application/screens/CustomReportView.vue")
      },
      {
        path: "/custom-report-create",
        name: "CustomReportCreate",
        component: () => import("@/application/screens/CustomReportCreate.vue")
      }
    ]
  }

  // Redirect to 404 page, if no match found
  // {
  //   path: "*",
  //   redirect: "/error-404"
  // }
];

const router = new VueRouter({
  routes,
  // mode: "history",
  // base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
