import { injectable, inject } from "inversify";
import GenotypeListScreenController from "@/adapters/controllers/screen-genotype-list";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { IGenotypeGateway } from "@/adapters/gateways/dna";

@injectable()
export default class SelectUpdateStatisticsInteractor {
  _genotypeListScreenController: GenotypeListScreenController;
  _genotypeGateway: IGenotypeGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(GenotypeListScreenController)
    genotypeListScreenController: GenotypeListScreenController,
    @inject("GenotypeGateway")
    genotypeGateway: IGenotypeGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._genotypeListScreenController = genotypeListScreenController;
    this._genotypeGateway = genotypeGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(pks: Array<string>): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }

    this._genotypeListScreenController.setAsLoading!();
    await this._genotypeGateway.updateStatistics(session.tokens, pks);
    this._genotypeListScreenController.setAsLoaded!();
  }
}
