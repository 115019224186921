import { injectable, inject } from "inversify";
import SnpCreateScreenController from "@/adapters/controllers/screen-snp-create";
import { ISnpGateway } from "@/adapters/gateways/dna";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { Snp } from "@/domain/dna/models";

@injectable()
export default class SubmitFormInteractor {
  _snpCreateScreenController: SnpCreateScreenController;
  _snpGateway: ISnpGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(SnpCreateScreenController)
    snpCreateScreenController: SnpCreateScreenController,
    @inject("SnpGateway")
    snpGateway: ISnpGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._snpCreateScreenController = snpCreateScreenController;
    this._snpGateway = snpGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(snp: Snp): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._snpCreateScreenController.setAsLoading!();
    await this._snpGateway.create(snp, session.tokens!);
    this._snpCreateScreenController.setAsLoaded!();
    this._routerController.pushSnpList!();
  }
}
