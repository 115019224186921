import axios from "axios";
import { injectable } from "inversify";

import * as models from "@/domain/administration/models";
import * as gateways from "@/adapters/gateways/administration";
import * as constants from "@/commons/constants";
import { IAdministrationGateway } from "@/adapters/gateways/administration";
import { Tokens } from "@/domain/users/models";
import { IDashboard } from "@/domain/administration/models";

@injectable()
export class AdministrationGateway implements IAdministrationGateway {
  URL: string = `${constants.SERVER_ADDRESS}/administration`;

  dashboard(tokens?: Tokens): Promise<IDashboard> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/dashboard/`, {
          headers: tokens!.getHeader()
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
