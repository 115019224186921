import { injectable, inject } from "inversify";
import ReportEditScreenController from "@/adapters/controllers/screen-report-edit";
import { IReportGateway } from "@/adapters/gateways/reports";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { Report } from "@/domain/reports/models";

@injectable()
export default class SubmitFormInteractor {
  _reportEditScreenController: ReportEditScreenController;
  _reportGateway: IReportGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(ReportEditScreenController)
    reportEditScreenController: ReportEditScreenController,
    @inject("ReportGateway")
    reportGateway: IReportGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._reportEditScreenController = reportEditScreenController;
    this._reportGateway = reportGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(report: Report): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._reportEditScreenController.setAsLoading!();
    await this._reportGateway.update(report, session.tokens!);
    this._reportEditScreenController.setAsLoaded!();
    this._routerController.pushReportList!();
  }
}
