import { injectable, inject } from "inversify";
import PlanCreateScreenController from "@/adapters/controllers/screen-plan-create";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class InitPlanCreateInteractor {
  _planCreateScreenController: PlanCreateScreenController;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(PlanCreateScreenController)
    planCreateScreenController: PlanCreateScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._planCreateScreenController = planCreateScreenController;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
  }
}
