import { injectable, inject } from "inversify";
import CorrelationCreateScreenController from "@/adapters/controllers/screen-correlation-create";
import { ICorrelationGateway } from "@/adapters/gateways/correlations";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { Correlation } from "@/domain/correlations/models";

@injectable()
export default class SubmitFormInteractor {
  _correlationCreateScreenController: CorrelationCreateScreenController;
  _correlationGateway: ICorrelationGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(CorrelationCreateScreenController)
    correlationCreateScreenController: CorrelationCreateScreenController,
    @inject("CorrelationGateway")
    correlationGateway: ICorrelationGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._correlationCreateScreenController = correlationCreateScreenController;
    this._correlationGateway = correlationGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(correlation: Correlation): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._correlationCreateScreenController.setAsLoading!();
    await this._correlationGateway.create(correlation, session.tokens!);
    this._correlationCreateScreenController.setAsLoaded!();
    this._routerController.pushCorrelationList!();
  }
}
