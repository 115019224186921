import { injectable, inject } from "inversify";
import CorrelationViewScreenController from "@/adapters/controllers/screen-correlation-view";
import { IUserGateway, ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class FindUserInteractor {
  _correlationViewScreenController: CorrelationViewScreenController;
  _userGateway: IUserGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(CorrelationViewScreenController)
    correlationViewScreenController: CorrelationViewScreenController,
    @inject("UserGateway")
    userGateway: IUserGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._correlationViewScreenController = correlationViewScreenController;
    this._userGateway = userGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(name: string): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._correlationViewScreenController.setAsSearchingUser!();
    const usersPage = await this._userGateway.list(
      session.tokens!,
      1, // page
      name, // search
      "name", // order
      5 // page size
    );
    this._correlationViewScreenController.setUsersFound!(usersPage.results);
  }
}
