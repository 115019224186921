import { injectable } from "inversify";
import UserCreateScreenData from "@/adapters/models/screen-user-create";

type EmptyAction = () => void;
type SetData = (data: UserCreateScreenData) => void;
type GetData = () => UserCreateScreenData;

@injectable()
export default class UserCreateScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
