import { injectable } from "inversify";
import SnpListScreenData from "@/adapters/models/screen-snp-list";

type EmptyAction = () => void;
type SetData = (data: SnpListScreenData) => void;
type GetData = () => SnpListScreenData;

@injectable()
export default class SnpListScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
