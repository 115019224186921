import { injectable, inject } from "inversify";
import GenomemosetCreateScreenController from "@/adapters/controllers/screen-genomemoset-create";
import { Genomemoset } from "@/domain/dna/models";
import { IGenomemosetGateway } from "@/adapters/gateways/dna";
import { RouterController } from "@/adapters/controllers/router";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class SubmitFormInteractor {
  _genomemosetCreateScreenController: GenomemosetCreateScreenController;
  _genomemosetGateway: IGenomemosetGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(GenomemosetCreateScreenController)
    genomemosetCreateScreenController: GenomemosetCreateScreenController,
    @inject("GenomemosetGateway")
    genomemosetGateway: IGenomemosetGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._genomemosetCreateScreenController = genomemosetCreateScreenController;
    this._genomemosetGateway = genomemosetGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(genomemoset: Genomemoset): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._genomemosetCreateScreenController.setAsLoading!();
    await this._genomemosetGateway.create(genomemoset, session.tokens!);
    this._genomemosetCreateScreenController.setAsLoaded!();
    this._routerController.pushGenomemosetList!();
  }
}
