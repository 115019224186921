import { injectable } from "inversify";
import PlanListScreenData from "@/adapters/models/screen-plan-list";

type EmptyAction = () => void;
type SetData = (data: PlanListScreenData) => void;
type GetData = () => PlanListScreenData;

@injectable()
export default class PlanListScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
