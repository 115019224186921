import { RouterController } from "@/adapters/controllers/router";
import CorrelationViewScreenController from "@/adapters/controllers/screen-correlation-view";
import { ICorrelationUserReportGateway } from "@/adapters/gateways/correlations";
import { ISessionGateway } from "@/adapters/gateways/users";
import { inject, injectable } from "inversify";
import { CorrelationUserReport } from "@/domain/correlations/models";

@injectable()
export default class SubmitNewUserReportInteractor {
  _correlationViewScreenController: CorrelationViewScreenController;
  _CorrelationUserReportGateway: ICorrelationUserReportGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(CorrelationViewScreenController)
    correlationViewScreenController: CorrelationViewScreenController,
    @inject("CorrelationUserReportGateway")
    CorrelationUserReportGateway: ICorrelationUserReportGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._correlationViewScreenController = correlationViewScreenController;
    this._CorrelationUserReportGateway = CorrelationUserReportGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(instance: CorrelationUserReport): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._correlationViewScreenController.setAsLoading!();
    await this._CorrelationUserReportGateway.create(instance, session.tokens);
    this._correlationViewScreenController.setAsLoaded!();
    this._correlationViewScreenController.reload!();
  }
}
