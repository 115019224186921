import { injectable, inject } from "inversify";
import CorrelationListScreenController from "@/adapters/controllers/screen-correlation-list";
import { ICorrelationGateway } from "@/adapters/gateways/correlations";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class ChangeFilterInteractor {
  _correlationListScreenController: CorrelationListScreenController;
  _sessionGateway: ISessionGateway;
  _correlationGateway: ICorrelationGateway;

  constructor(
    @inject(CorrelationListScreenController)
    correlationListScreenController: CorrelationListScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject("CorrelationGateway")
    correlationGateway: ICorrelationGateway
  ) {
    this._correlationListScreenController = correlationListScreenController;
    this._correlationGateway = correlationGateway;
    this._sessionGateway = sessionGateway;
  }

  async handle(page: number, search: string, sort: string): Promise<void> {
    this._correlationListScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    const correlationsPage = await this._correlationGateway.list(
      session.tokens!,
      page,
      search,
      sort
    );
    this._correlationListScreenController.setData!({ correlationsPage });
    this._correlationListScreenController.setAsLoaded!();
  }
}
