import { injectable, inject } from "inversify";
import CustomReportListScreenController from "@/adapters/controllers/screen-report-list";
import { RouterController } from "@/adapters/controllers/router";
import { CustomReport } from "@/domain/reports/models";

@injectable()
export default class SelectCustomReportEditInteractor {
  _reportListScreenController: CustomReportListScreenController;
  _routerController: RouterController;

  constructor(
    @inject(CustomReportListScreenController)
    reportListScreenController: CustomReportListScreenController,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._reportListScreenController = reportListScreenController;
    this._routerController = routerController;
  }

  async handle(report: CustomReport): Promise<void> {
    this._routerController.pushCustomReportEdit!(report.id);
  }
}
