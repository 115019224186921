import { injectable, inject } from "inversify";
import CustomReportCreateScreenController from "@/adapters/controllers/screen-custom-report-create";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class InitCustomReportCreateInteractor {
  _customReportCreateScreenController: CustomReportCreateScreenController;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(CustomReportCreateScreenController)
    customReportCreateScreenController: CustomReportCreateScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._customReportCreateScreenController = customReportCreateScreenController;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
  }
}
