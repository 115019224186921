import { injectable } from "inversify";
import CorrelationCreateScreenData from "@/adapters/models/screen-correlation-create";

type EmptyAction = () => void;
type SetData = (data: CorrelationCreateScreenData) => void;
type GetData = () => CorrelationCreateScreenData;

@injectable()
export default class CorrelationCreateScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
