import { injectable, inject } from "inversify";
import SlotEditScreenController from "@/adapters/controllers/screen-slot-edit";
import { ISlotGateway } from "@/adapters/gateways/slots";
import { RouterController } from "@/adapters/controllers/router";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class InitSlotEditInteractor {
  _slotEditScreenController: SlotEditScreenController;
  _slotGateway: ISlotGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(SlotEditScreenController)
    slotEditScreenController: SlotEditScreenController,
    @inject("SlotGateway")
    slotGateway: ISlotGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._slotEditScreenController = slotEditScreenController;
    this._slotGateway = slotGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(id: string): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._slotEditScreenController.setAsLoading!();
    const slot = await this._slotGateway.retrieve(id, session.tokens!);
    slot.rootId = slot.root!.id;
    this._slotEditScreenController.setData!({ slot });
    this._slotEditScreenController.setAsLoaded!();
  }
}
