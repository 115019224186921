import { injectable } from "inversify";
import ResearchEditScreenData from "@/adapters/models/screen-research-edit";

type EmptyAction = () => void;
type SetData = (data: ResearchEditScreenData) => void;
type GetData = () => ResearchEditScreenData;

@injectable()
export default class ResearchEditScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
