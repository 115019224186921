import { injectable, inject } from "inversify";
import UserListScreenController from "@/adapters/controllers/screen-user-list";
import { IUserGateway, ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class InitUserListInteractor {
  _userListScreenController: UserListScreenController;
  _userGateway: IUserGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(UserListScreenController)
    userListScreenController: UserListScreenController,
    @inject("UserGateway")
    userGateway: IUserGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._userListScreenController = userListScreenController;
    this._userGateway = userGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(wejoyUserEmail?: string): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._userListScreenController.setAsLoading!();
    const usersPage = await this._userGateway.list(session.tokens!);
    this._userListScreenController.setData!({ usersPage, wejoyUserEmail });
    this._userListScreenController.setAsLoaded!();
  }
}
