import { injectable, inject } from "inversify";
import UserListScreenController from "@/adapters/controllers/screen-user-list";
import { IUserGateway, ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class ChangeFilterInteractor {
  _userListScreenController: UserListScreenController;
  _userGateway: IUserGateway;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(UserListScreenController)
    userListScreenController: UserListScreenController,
    @inject("UserGateway")
    userGateway: IUserGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._userListScreenController = userListScreenController;
    this._userGateway = userGateway;
    this._sessionGateway = sessionGateway;
  }

  async handle(page: number, search: string, sort: string): Promise<void> {
    this._userListScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    const data = this._userListScreenController.getData!();
    const usersPage = await this._userGateway.list(
      session.tokens!,
      page,
      search,
      sort
    );
    this._userListScreenController.setData!({
      usersPage,
      wejoyUserEmail: data.wejoyUserEmail
    });
    this._userListScreenController.setAsLoaded!();
  }
}
