import { injectable, inject } from "inversify";
import CustomReportListScreenController from "@/adapters/controllers/screen-custom-report-list";

@injectable()
export default class SelectCustomReportViewInteractor {
  _customReportListScreenController: CustomReportListScreenController;

  constructor(
    @inject(CustomReportListScreenController)
    customReportListScreenController: CustomReportListScreenController
  ) {
    this._customReportListScreenController = customReportListScreenController;
  }

  async handle(): Promise<void> {}
}
