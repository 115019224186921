import { injectable } from "inversify";
import ReportListScreenData from "@/adapters/models/screen-report-list";

type EmptyAction = () => void;
type SetData = (data: ReportListScreenData) => void;
type GetData = () => ReportListScreenData;

@injectable()
export default class ReportListScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
