import { injectable, inject } from "inversify";
import BehaviourListScreenController from "@/adapters/controllers/screen-behaviour-list";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { IBehaviourGateway } from "@/adapters/gateways/behaviours";

@injectable()
export default class InitBehaviourListInteractor {
  _behaviourListScreenController: BehaviourListScreenController;

  _sessionGateway: ISessionGateway;
  _routerController: RouterController;
  _behaviourGateway: IBehaviourGateway;

  constructor(
    @inject(BehaviourListScreenController)
    behaviourScreenController: BehaviourListScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject("BehaviourGateway")
    behaviourGateway: IBehaviourGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._behaviourListScreenController = behaviourScreenController;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
    this._behaviourGateway = behaviourGateway;
  }

  async handle(): Promise<void> {
    this._behaviourListScreenController.setAsLoading!();
    let session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }

    const behavioursPage = await this._behaviourGateway.list(session.tokens!);
    this._behaviourListScreenController.setData!({ behavioursPage });
    this._behaviourListScreenController.setAsLoaded!();
  }
}
