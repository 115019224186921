import { injectable } from "inversify";
import RobotEditScreenData from "@/adapters/models/screen-robot-edit";

type EmptyAction = () => void;
type SetData = (data: RobotEditScreenData) => void;
type GetData = () => RobotEditScreenData;

@injectable()
export default class RobotEditScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
