import { injectable } from "inversify";
import GenotypeCreateScreenData from "@/adapters/models/screen-genotype-create";

type EmptyAction = () => void;
type SetData = (data: GenotypeCreateScreenData) => void;
type GetData = () => GenotypeCreateScreenData;

@injectable()
export default class GenotypeCreateScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
