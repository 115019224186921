import { injectable, inject } from "inversify";
import CorrelationListScreenController from "@/adapters/controllers/screen-correlation-list";
import { Correlation } from "@/domain/correlations/models";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class SelectCorrelationEditInteractor {
  _correlationListScreenController: CorrelationListScreenController;
  _routerController: RouterController;

  constructor(
    @inject(CorrelationListScreenController)
    correlationListScreenController: CorrelationListScreenController,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._correlationListScreenController = correlationListScreenController;
    this._routerController = routerController;
  }

  async handle(correlation: Correlation): Promise<void> {
    this._routerController.pushCorrelationEdit!(correlation.id);
  }
}
