import "reflect-metadata";
import container from "@/application/injector";
import Vue from "vue";
import App from "@/application/screens/App.vue";
// import "./registerServiceWorker";
import router from "@/application/router";
import store from "@/application/store/store";
import "animate.css/animate.min.css";
import VueAnalytics from "vue-analytics";

require("./vuexy.js");
// require("./socket.js");
require("./window.js");
require("./wysiwyg.js");

Vue.config.productionTip = false;

Vue.prototype.$injector = container;
Vue.prototype.$l = container.get("Localization");

Vue.use(VueAnalytics, {
  id: "UA-164320724-2"
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
