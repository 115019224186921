import { injectable, inject } from "inversify";
import CorrelationViewScreenController from "@/adapters/controllers/screen-correlation-view";
import {
  ICorrelationGateway,
  ICorrelationGroupGateway,
  ICorrelationGroupUserGateway,
  ICorrelationUserReportGateway
} from "@/adapters/gateways/correlations";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class InitCorrelationViewInteractor {
  _correlationViewScreenController: CorrelationViewScreenController;
  _correlationGateway: ICorrelationGateway;
  _correlationGroupGateway: ICorrelationGroupGateway;
  _correlationGroupUserGateway: ICorrelationGroupUserGateway;
  _correlationUserReportGateway: ICorrelationUserReportGateway;
  // _userGateway: IUserGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(CorrelationViewScreenController)
    correlationViewScreenController: CorrelationViewScreenController,
    @inject("CorrelationGateway")
    correlationGateway: ICorrelationGateway,
    @inject("CorrelationGroupGateway")
    correlationGroupGateway: ICorrelationGroupGateway,
    @inject("CorrelationGroupUserGateway")
    correlationGroupUserGateway: ICorrelationGroupUserGateway,
    @inject("CorrelationUserReportGateway")
    correlationUserReportGateway: ICorrelationUserReportGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._correlationViewScreenController = correlationViewScreenController;
    this._correlationGateway = correlationGateway;
    this._correlationGroupGateway = correlationGroupGateway;
    this._correlationGroupUserGateway = correlationGroupUserGateway;
    this._correlationUserReportGateway = correlationUserReportGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(id: string): Promise<void> {
    this._correlationViewScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    const correlation = await this._correlationGateway.retrieve(
      session.tokens!,
      id
    );
    const groupsRequest = this._correlationGroupGateway.list(
      session.tokens,
      correlation
    );
    const usersGroupsRequest = this._correlationGroupUserGateway.list(
      session.tokens!,
      correlation
    );
    const resultsRequest = this._correlationGateway.results(
      session.tokens!,
      correlation
    );
    const usersReportsRequest = this._correlationUserReportGateway.list(
      session.tokens!,
      correlation
    );

    const groups = await groupsRequest;
    const results = await resultsRequest;
    const usersGroups = await usersGroupsRequest;
    const usersReports = await usersReportsRequest;

    for (const group of groups) {
      group.users = [];
    }

    for (const userGroup of usersGroups) {
      // const user = users.results.find(e => e.id == userGroup.user);
      const group = groups.find(e => e.id == userGroup.group);
      if (group == undefined) continue;
      if (group.users == undefined) {
        group.users = [];
      }
      group.users.push(userGroup);
    }

    this._correlationViewScreenController.setData!({
      correlation,
      results,
      groups,
      usersReports
    });

    this._correlationViewScreenController.setAsLoaded!();
  }
}
