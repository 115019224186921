import { injectable } from "inversify";
import ResearchDetailsScreenData from "@/adapters/models/screen-research-details";

type EmptyAction = () => void;
type SetData = (data: ResearchDetailsScreenData) => void;
type GetData = () => ResearchDetailsScreenData;
type ValuesAction = (values: Array<String>) => void;

@injectable()
export default class ResearchDetailsScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
  showCellResults?: ValuesAction;
}
