import { injectable } from "inversify";
import SlotListScreenData from "@/adapters/models/screen-slot-list";

type EmptyAction = () => void;
type SetData = (data: SlotListScreenData) => void;
type GetData = () => SlotListScreenData;

@injectable()
export default class SlotListScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
