import { injectable } from "inversify";
import SnpFrequencyListScreenData from "@/adapters/models/screen-snp-frequency-list";

type EmptyAction = () => void;
type SetData = (data: SnpFrequencyListScreenData) => void;
type GetData = () => SnpFrequencyListScreenData;

@injectable()
export default class SnpFrequencyListScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
