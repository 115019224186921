import { RouterController } from "@/adapters/controllers/router";
import CorrelationEditScreenController from "@/adapters/controllers/screen-correlation-edit";
import { ICorrelationGateway } from "@/adapters/gateways/correlations";
import { ISessionGateway } from "@/adapters/gateways/users";
import { Correlation } from "@/domain/correlations/models";
import { inject, injectable } from "inversify";

@injectable()
export default class SubmitFormInteractor {
  _correlationEditScreenController: CorrelationEditScreenController;
  _correlationGateway: ICorrelationGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(CorrelationEditScreenController)
    correlationEditScreenController: CorrelationEditScreenController,
    @inject("CorrelationGateway")
    correlationGateway: ICorrelationGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._correlationEditScreenController = correlationEditScreenController;
    this._correlationGateway = correlationGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(correlation: Correlation): Promise<void> {
    this._correlationEditScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    await this._correlationGateway.update(session.tokens!, correlation);
    this._routerController.pushCorrelationList!();
    this._correlationEditScreenController.setAsLoaded!();
  }
}
