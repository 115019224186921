import { injectable, inject } from "inversify";
import ReportPlusViewScreenController from "@/adapters/controllers/screen-report-plus-view";

@injectable()
export default class InitReportPlusViewInteractor {
  _reportPlusViewScreenController: ReportPlusViewScreenController;

  constructor(
    @inject(ReportPlusViewScreenController)
    reportPlusViewScreenController: ReportPlusViewScreenController
  ) {
    this._reportPlusViewScreenController = reportPlusViewScreenController;
  }

  async handle(): Promise<void> {}
}
