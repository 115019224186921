import { injectable } from "inversify";
import OverviewScreenData from "@/adapters/models/screen-overview";

type EmptyAction = () => void;
type SetData = (data: OverviewScreenData) => void;
type GetData = () => OverviewScreenData;

@injectable()
export default class OverviewScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
