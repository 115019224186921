import { injectable } from "inversify";
import CustomReportCreateScreenData from "@/adapters/models/screen-custom-report-create";

type EmptyAction = () => void;
type SetData = (data: CustomReportCreateScreenData) => void;
type GetData = () => CustomReportCreateScreenData;

@injectable()
export default class CustomReportCreateScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
