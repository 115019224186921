import { injectable, inject } from "inversify";
import GenomemosetEditScreenController from "@/adapters/controllers/screen-genomemoset-edit";
import { IGenomemosetGateway } from "@/adapters/gateways/dna";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { Genomemoset } from "@/domain/dna/models";

@injectable()
export default class SubmitFormInteractor {
  _genomemosetEditScreenController: GenomemosetEditScreenController;
  _genomemosetGateway: IGenomemosetGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(GenomemosetEditScreenController)
    genomemosetEditScreenController: GenomemosetEditScreenController,
    @inject("GenomemosetGateway")
    genomemosetGateway: IGenomemosetGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._genomemosetEditScreenController = genomemosetEditScreenController;
    this._genomemosetGateway = genomemosetGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(genomemoset: Genomemoset): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._genomemosetEditScreenController.setAsLoading!();
    await this._genomemosetGateway.update(genomemoset, session.tokens!);
    this._genomemosetEditScreenController.setAsLoaded!();
    this._routerController.pushGenomemosetList!();
  }
}
