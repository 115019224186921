import { injectable, inject } from "inversify";
import RobotViewScreenController from "@/adapters/controllers/screen-robot-view";
import { RouterController } from "@/adapters/controllers/router";
import { IRobotGateway } from "@/adapters/gateways/robots";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class RefreshProgressInteractor {
  _robotViewScreenController: RobotViewScreenController;
  _robotGateway: IRobotGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(RobotViewScreenController)
    robotViewScreenController: RobotViewScreenController,
    @inject("RobotGateway")
    robotGateway: IRobotGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._robotViewScreenController = robotViewScreenController;
    this._robotGateway = robotGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(id: string): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    const robot = await this._robotGateway.retrieve(session.tokens!, id);
    const data = this._robotViewScreenController.getData!();

    this._robotViewScreenController.setData!({
      robot,
      samples: data.samples,
      tests: data.tests
    });
  }
}
