import { RouterController } from "@/adapters/controllers/router";
import GenotypeCreateScreenController from "@/adapters/controllers/screen-genotype-create";
import { ISessionGateway } from "@/adapters/gateways/users";
import { inject, injectable } from "inversify";

@injectable()
export default class InitGenotypeCreateInteractor {
  _genotypeCreateScreenController: GenotypeCreateScreenController;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(GenotypeCreateScreenController)
    genotypeCreateScreenController: GenotypeCreateScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._genotypeCreateScreenController = genotypeCreateScreenController;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
  }
}
