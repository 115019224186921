import { injectable, inject } from "inversify";
import GenomemosetCreateScreenController from "@/adapters/controllers/screen-genomemoset-create";
import { RouterController } from "@/adapters/controllers/router";
import { ISessionGateway } from "@/adapters/gateways/users";
import { IResearchGateway } from "@/adapters/gateways/researches";

@injectable()
export default class InitGenomemosetCreateInteractor {
  _genomemosetCreateScreenController: GenomemosetCreateScreenController;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;
  _researchGateway: IResearchGateway;

  constructor(
    @inject(GenomemosetCreateScreenController)
    genomemosetCreateScreenController: GenomemosetCreateScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("ResearchGateway")
    researchGateway: IResearchGateway
  ) {
    this._genomemosetCreateScreenController = genomemosetCreateScreenController;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
    this._researchGateway = researchGateway;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._genomemosetCreateScreenController.setAsLoading!();
    const diagnostics = await this._researchGateway.listDiagnostics(
      session.tokens!
    );
    this._genomemosetCreateScreenController.setData!({ diagnostics });
    this._genomemosetCreateScreenController.setAsLoaded!();
  }
}
