import { injectable, inject } from "inversify";
import PlanListScreenController from "@/adapters/controllers/screen-plan-list";
import { IPlanGateway } from "@/adapters/gateways/plans";
import { RouterController } from "@/adapters/controllers/router";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class InitPlanListInteractor {
  _planListScreenController: PlanListScreenController;
  _planGateway: IPlanGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(PlanListScreenController)
    planListScreenController: PlanListScreenController,
    @inject("PlanGateway")
    planGateway: IPlanGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._planListScreenController = planListScreenController;
    this._planGateway = planGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._planListScreenController.setAsLoading!();
    const plansPage = await this._planGateway.list(session.tokens!);
    this._planListScreenController.setData!({ plansPage });
    this._planListScreenController.setAsLoaded!();
  }
}
