import { injectable, inject } from "inversify";
import UserListScreenController from "@/adapters/controllers/screen-user-list";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class SelectCreateInteractor {
  _routerController: RouterController;

  constructor(
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._routerController = routerController;
  }

  async handle(wejoyUserEmail?: string): Promise<void> {
    this._routerController.pushUserCreate!(wejoyUserEmail);
  }
}
