import { injectable } from "inversify";
import BehaviourListScreenData from "@/adapters/models/screen-behaviour-list";

type EmptyAction = () => void;
type SetData = (data: BehaviourListScreenData) => void;
type GetData = () => BehaviourListScreenData;

@injectable()
export default class BehaviourListScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
