import { injectable } from "inversify";
import BehaviourCreateScreenData from "@/adapters/models/screen-behaviour-create";

type EmptyAction = () => void;
type SetData = (data: BehaviourCreateScreenData) => void;
type GetData = () => BehaviourCreateScreenData;

@injectable()
export default class BehaviourCreateScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
