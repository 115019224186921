import { injectable, inject } from "inversify";
import GenosetListScreenController from "@/adapters/controllers/screen-genoset-list";
import { IGenosetGateway } from "@/adapters/gateways/dna";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class ChangeFilterInteractor {
  _genosetListScreenController: GenosetListScreenController;
  _genosetGateway: IGenosetGateway;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(GenosetListScreenController)
    genosetListScreenController: GenosetListScreenController,
    @inject("GenosetGateway")
    genosetGateway: IGenosetGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._genosetListScreenController = genosetListScreenController;
    this._genosetGateway = genosetGateway;
    this._sessionGateway = sessionGateway;
  }

  async handle(page: number, search: string, sort: string): Promise<void> {
    this._genosetListScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    const genosetsPage = await this._genosetGateway.list(
      session.tokens!,
      page,
      search,
      sort
    );
    this._genosetListScreenController.setData!({ genosetsPage });
    this._genosetListScreenController.setAsLoaded!();
  }
}
