import { injectable, inject } from "inversify";
import BehaviourListScreenController from "@/adapters/controllers/screen-behaviour-list";
import { Behaviour } from "@/domain/behaviours/models";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class SelectBehaviourEditInteractor {
  _behaviourListScreenController: BehaviourListScreenController;
  _routerController: RouterController;

  constructor(
    @inject(BehaviourListScreenController)
    behaviourListScreenController: BehaviourListScreenController,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._behaviourListScreenController = behaviourListScreenController;
    this._routerController = routerController;
  }

  async handle(behaviour: Behaviour): Promise<void> {
    this._routerController.pushBehaviourEdit!(behaviour.id);
  }
}
