import { injectable } from "inversify";
import LoginScreenData from "@/adapters/models/screen-login";

type EmptyAction = () => void;
type SetData = (data: LoginScreenData) => void;
type GetData = () => LoginScreenData;

@injectable()
export default class LoginScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
  clearFormErrors?: EmptyAction;
  setAsUsernameInvalid?: EmptyAction;
  setAsPasswordInvalid?: EmptyAction;
  setAsUsernameAndPasswordInvalid?: EmptyAction;
  setAsPermissionInvalid?: EmptyAction;
}
