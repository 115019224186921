import { injectable, inject } from "inversify";
import UserListScreenController from "@/adapters/controllers/screen-user-list";
import { RouterController } from "@/adapters/controllers/router";
import { User } from "@/domain/users/models";

@injectable()
export default class SelectUserEditInteractor {
  _routerController: RouterController;

  constructor(
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._routerController = routerController;
  }

  async handle(instance: User, wejoyUserEmail?: string): Promise<void> {
    this._routerController.pushUserEdit!(instance.id, wejoyUserEmail);
  }
}
