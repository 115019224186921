import { injectable } from "inversify";
import ReportPlusEditScreenData from "@/adapters/models/screen-report-plus-edit";

type EmptyAction = () => void;
type SetData = (data: ReportPlusEditScreenData) => void;
type GetData = () => ReportPlusEditScreenData;

@injectable()
export default class ReportPlusEditScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
