import { injectable } from "inversify";

type EmptyAction = () => void;
type PushAction = (route: string) => void;
type OpenAction = (route: string) => void;
type IdAction = (id: string) => void;
type StrOptionalAction = (str?: string) => void;
type IdWithStrOptionalAction = (id: string, str?: string) => void;

@injectable()
export class RouterController {
  pop?: EmptyAction;
  push?: PushAction;
  open?: OpenAction;
  pushUrl?: PushAction;
  openUrl?: OpenAction;
  pushLogin?: EmptyAction;
  openLogin?: EmptyAction;
  pushOverview?: EmptyAction;
  openOverview?: EmptyAction;
  pushBehaviourEdit?: IdAction;
  openBehaviourEdit?: IdAction;
  pushBehaviourList?: EmptyAction;
  openBehaviourList?: EmptyAction;
  pushGenosetList?: EmptyAction;
  openGenosetList?: EmptyAction;
  pushGenosetCreate?: EmptyAction;
  openGenosetCreate?: EmptyAction;
  pushGenosetEdit?: IdAction;
  openGenosetEdit?: IdAction;
  pushSnpList?: EmptyAction;
  openSnpList?: EmptyAction;
  pushSnpEdit?: IdAction;
  openSnpEdit?: IdAction;
  pushSnpCreate?: EmptyAction;
  openSnpCreate?: EmptyAction;
  pushGenotypeList?: EmptyAction;
  openGenotypeList?: EmptyAction;
  pushGenotypeCreate?: EmptyAction;
  openGenotypeCreate?: EmptyAction;
  pushGenotypeEdit?: IdAction;
  openGenotypeEdit?: IdAction;
  pushBehaviourCreate?: EmptyAction;
  openBehaviourCreate?: EmptyAction;
  pushReportList?: EmptyAction;
  openReportList?: EmptyAction;
  pushReportCreate?: EmptyAction;
  openReportCreate?: EmptyAction;
  pushReportEdit?: IdAction;
  openReportEdit?: IdAction;
  pushPlanList?: EmptyAction;
  openPlanList?: EmptyAction;
  pushPlanCreate?: EmptyAction;
  openPlanCreate?: EmptyAction;
  pushPlanEdit?: IdAction;
  openPlanEdit?: IdAction;
  pushUserList?: EmptyAction;
  openUserList?: EmptyAction;
  pushUserCreate?: StrOptionalAction;
  openUserCreate?: StrOptionalAction;
  pushUserEdit?: IdWithStrOptionalAction;
  openUserEdit?: IdWithStrOptionalAction;
  pushResearchList?: EmptyAction;
  openResearchList?: EmptyAction;
  pushResearchCreate?: EmptyAction;
  openResearchCreate?: EmptyAction;
  pushResearchEdit?: IdAction;
  openResearchEdit?: IdAction;
  pushResearchDetails?: IdAction;
  openResearchDetails?: IdAction;
  pushPlanCodeList?: EmptyAction;
  openPlanCodeList?: EmptyAction;
  pushPlanCodeCreate?: EmptyAction;
  openPlanCodeCreate?: EmptyAction;
  pushGenomemosetCreate?: EmptyAction;
  openGenomemosetCreate?: EmptyAction;
  pushGenomemosetList?: EmptyAction;
  openGenomemosetList?: EmptyAction;
  pushGenomemosetEdit?: IdAction;
  openGenomemosetEdit?: IdAction;
  pushCorrelationList?: EmptyAction;
  openCorrelationList?: EmptyAction;
  pushCorrelationCreate?: EmptyAction;
  openCorrelationCreate?: EmptyAction;
  pushCorrelationEdit?: IdAction;
  openCorrelationEdit?: IdAction;
  pushCorrelationView?: IdAction;
  openCorrelationView?: IdAction;
  pushSnpFrequencyList?: EmptyAction;
  openSnpFrequencyList?: EmptyAction;
  pushSlotList?: EmptyAction;
  openSlotList?: EmptyAction;
  pushSlotCreate?: EmptyAction;
  openSlotCreate?: EmptyAction;
  pushSlotEdit?: IdAction;
  openSlotEdit?: IdAction;
  pushRobotList?: EmptyAction;
  openRobotList?: EmptyAction;
  pushRobotCreate?: EmptyAction;
  openRobotCreate?: EmptyAction;
  pushRobotEdit?: IdAction;
  openRobotEdit?: IdAction;
  pushRobotView?: IdAction;
  openRobotView?: IdAction;
  pushReportPlusCreate?: EmptyAction;
  openReportPlusCreate?: EmptyAction;
  pushReportPlusEdit?: EmptyAction;
  openReportPlusEdit?: EmptyAction;
  pushReportPlusList?: EmptyAction;
  openReportPlusList?: EmptyAction;
  pushReportPlusView?: EmptyAction;
  openReportPlusView?: EmptyAction;
  pushCustomReportCreate?: EmptyAction;
  openCustomReportCreate?: EmptyAction;
  pushCustomReportEdit?: IdAction;
  openCustomReportEdit?: IdAction;
  pushCustomReportList?: EmptyAction;
  openCustomReportList?: EmptyAction;
  pushCustomReportView?: IdAction;
  openCustomReportView?: IdAction;
}
