import { injectable, inject } from "inversify";
import ReportListScreenController from "@/adapters/controllers/screen-report-list";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { IReportGateway } from "@/adapters/gateways/reports";

@injectable()
export default class InitReportListInteractor {
  _reportListScreenController: ReportListScreenController;

  _sessionGateway: ISessionGateway;
  _routerController: RouterController;
  _reportGateway: IReportGateway;

  constructor(
    @inject(ReportListScreenController)
    overviewScreenController: ReportListScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject("ReportGateway")
    reportGateway: IReportGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._reportListScreenController = overviewScreenController;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
    this._reportGateway = reportGateway;
  }

  async handle(): Promise<void> {
    this._reportListScreenController.setAsLoading!();
    let session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }

    const reportsPage = await this._reportGateway.list(session.tokens!);
    this._reportListScreenController.setData!({ reportsPage });
    this._reportListScreenController.setAsLoaded!();
  }
}
