import { injectable, inject } from "inversify";
import PlanCodeCreateScreenController from "@/adapters/controllers/screen-plan-code-create";
import { IPlanCodeGateway } from "@/adapters/gateways/plans";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { PlanCode } from "@/domain/plans/models";

@injectable()
export default class SubmitFormInteractor {
  _planCodeCreateScreenController: PlanCodeCreateScreenController;
  _planCodeGateway: IPlanCodeGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(PlanCodeCreateScreenController)
    planCodeCreateScreenController: PlanCodeCreateScreenController,
    @inject("PlanCodeGateway")
    planCodeGateway: IPlanCodeGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._planCodeCreateScreenController = planCodeCreateScreenController;
    this._planCodeGateway = planCodeGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(instance: PlanCode): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._planCodeCreateScreenController.setAsLoading!();
    await this._planCodeGateway.create(instance, session.tokens!);
    this._planCodeCreateScreenController.setAsLoaded!();
    this._routerController.pushPlanCodeList!();
  }
}
