import { injectable, inject } from "inversify";
import SnpEditScreenController from "@/adapters/controllers/screen-snp-edit";
import { ISnpGateway } from "@/adapters/gateways/dna";
import { RouterController } from "@/adapters/controllers/router";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class InitSnpEditInteractor {
  _snpEditScreenController: SnpEditScreenController;
  _snpGateway: ISnpGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(SnpEditScreenController)
    snpEditScreenController: SnpEditScreenController,
    @inject("SnpGateway")
    snpGateway: ISnpGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._snpEditScreenController = snpEditScreenController;
    this._snpGateway = snpGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(id: string): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._snpEditScreenController.setAsLoading!();
    const snp = await this._snpGateway.retrieve(id, session.tokens!);
    this._snpEditScreenController.setData!({ snp });
    this._snpEditScreenController.setAsLoaded!();
  }
}
