import { injectable } from "inversify";
import CorrelationEditScreenData from "@/adapters/models/screen-correlation-edit";

type EmptyAction = () => void;
type SetData = (data: CorrelationEditScreenData) => void;
type GetData = () => CorrelationEditScreenData;

@injectable()
export default class CorrelationEditScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
