import { injectable, inject } from "inversify";
import RobotListScreenController from "@/adapters/controllers/screen-robot-list";
import { RouterController } from "@/adapters/controllers/router";
import { IRobotGateway } from "@/adapters/gateways/robots";
import { ISessionGateway } from "@/adapters/gateways/users";
import { Robot } from "@/domain/robots/models";

@injectable()
export default class SelectRobotDuplicateInteractor {
  _robotListScreenController: RobotListScreenController;

  _sessionGateway: ISessionGateway;
  _routerController: RouterController;
  _robotGateway: IRobotGateway;

  constructor(
    @inject(RobotListScreenController)
    robotScreenController: RobotListScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject("RobotGateway")
    robotGateway: IRobotGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._robotListScreenController = robotScreenController;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
    this._robotGateway = robotGateway;
  }

  async handle(instance: Robot): Promise<void> {
    this._robotListScreenController.setAsLoading!();
    let session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }

    await this._robotGateway.duplicate(session.tokens!, instance);
    const robotsPage = await this._robotGateway.list(session.tokens!);
    this._robotListScreenController.setData!({ robotsPage });
    this._robotListScreenController.setAsLoaded!();
  }
}
