import { injectable, inject } from "inversify";
import PlanCreateScreenController from "@/adapters/controllers/screen-plan-create";
import { IPlanGateway } from "@/adapters/gateways/plans";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { Plan } from "@/domain/plans/models";

@injectable()
export default class SubmitFormInteractor {
  _planCreateScreenController: PlanCreateScreenController;
  _planGateway: IPlanGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(PlanCreateScreenController)
    planCreateScreenController: PlanCreateScreenController,
    @inject("PlanGateway")
    planGateway: IPlanGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._planCreateScreenController = planCreateScreenController;
    this._planGateway = planGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(plan: Plan): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._planCreateScreenController.setAsLoading!();
    await this._planGateway.create(plan, session.tokens!);
    this._planCreateScreenController.setAsLoaded!();
    this._routerController.pushPlanList!();
  }
}
