import axios from "axios";
import { injectable } from "inversify";

import * as models from "@/domain/snps-frequencies/models";
import * as gateways from "@/adapters/gateways/snps-frequencies";
import * as constants from "@/commons/constants";
import { Tokens } from "@/domain/users/models";

@injectable()
export class SnpFrequencyServerGateway
  implements gateways.ISnpFrequencyGateway {
  URL: string = `${constants.SERVER_ADDRESS}/snps-frequencies/snp-frequency`;

  list(
    tokens: Tokens,
    page?: number,
    search?: string,
    sort?: string,
    pageSize?: number
  ): Promise<Array<models.SnpFrequency>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(tokens: Tokens, query: any): Promise<Array<models.SnpFrequency>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(tokens: Tokens, instance: models.SnpFrequency): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(tokens: Tokens, id: string): Promise<models.SnpFrequency> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(tokens: Tokens, instance: models.SnpFrequency): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(tokens: Tokens, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class SnpFrequencyReportServerGateway
  implements gateways.ISnpFrequencyReportGateway {
  URL: string = `${constants.SERVER_ADDRESS}/snps-frequencies/snp-frequency-report`;

  list(
    tokens: Tokens,
    page?: number,
    search?: string,
    sort?: string,
    pageSize?: number
  ): Promise<Array<models.SnpFrequencyReport>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(
    tokens: Tokens,
    query: any
  ): Promise<Array<models.SnpFrequencyReport>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(tokens: Tokens, instance: models.SnpFrequencyReport): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(tokens: Tokens, id: string): Promise<models.SnpFrequencyReport> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(tokens: Tokens, instance: models.SnpFrequencyReport): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(tokens: Tokens, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class SnpInfoServerGateway implements gateways.ISnpInfoGateway {
  URL: string = `${constants.SERVER_ADDRESS}/snps-frequencies/snp-info`;

  list(
    tokens: Tokens,
    page?: number,
    search?: string,
    sort?: string,
    pageSize?: number
  ): Promise<Array<models.SnpInfo>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(tokens: Tokens, query: any): Promise<Array<models.SnpInfo>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(tokens: Tokens, instance: models.SnpInfo): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(tokens: Tokens, id: string): Promise<models.SnpInfo> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(tokens: Tokens, instance: models.SnpInfo): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(tokens: Tokens, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
