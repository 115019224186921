import { injectable } from "inversify";
import CorrelationListScreenData from "@/adapters/models/screen-correlation-list";

type EmptyAction = () => void;
type SetData = (data: CorrelationListScreenData) => void;
type GetData = () => CorrelationListScreenData;

@injectable()
export default class CorrelationListScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
