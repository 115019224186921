import texts from "@/../texts.json";
const capitalize = require("capitalize");
import { STORAGE_LANG } from "@/commons/constants";
import { injectable } from "inversify";

@injectable()
export class Localization {
  texts: object = texts;
  lang: string;
  langDefault: string = "en-US";

  constructor() {
    this.lang = localStorage.getItem(STORAGE_LANG) || navigator.language;
  }

  // https://github.com/grncdr/js-capitalize
  applyCase(text: string, textCase?: string): string {
    switch (textCase) {
      case "capitalize-words":
        return capitalize.words(text);
      case "capitalize":
        return capitalize(text);
      case "upper":
        return text.toUpperCase();
      case "lower":
        return text.toLowerCase();
      default:
        return text;
    }
  }

  get(path: string, textCase?: string): string {
    const parts = path.split(".");
    let text: any = this.texts;
    for (let i in parts) {
      const part = parts[i];
      if (text.hasOwnProperty(part)) {
        text = text[part];
      } else {
        return "";
      }
    }
    if (text.hasOwnProperty(this.lang)) {
      return this.applyCase(text[this.lang] as string, textCase);
    } else if (text.hasOwnProperty(this.langDefault)) {
      return this.applyCase(text[this.langDefault] as string, textCase);
    } else {
      return "";
    }
  }

  get isPtBr(): boolean {
    return this.lang == "pt-BR";
  }

  get isEsEs(): boolean {
    return this.lang == "es-ES";
  }

  get isEnUs(): boolean {
    return this.lang == "en-US";
  }

  set(lang: string) {
    this.lang = lang;
    localStorage.setItem(STORAGE_LANG, lang);
  }
}
