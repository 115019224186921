import { injectable, inject } from "inversify";
import ReportPlusListScreenController from "@/adapters/controllers/screen-report-plus-list";

@injectable()
export default class InitReportPlusListInteractor {
  _reportPlusListScreenController: ReportPlusListScreenController;

  constructor(
    @inject(ReportPlusListScreenController)
    reportPlusListScreenController: ReportPlusListScreenController
  ) {
    this._reportPlusListScreenController = reportPlusListScreenController;
  }

  async handle(): Promise<void> {}
}
