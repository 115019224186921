import { injectable } from "inversify";
import ResearchListScreenData from "@/adapters/models/screen-research-list";

type EmptyAction = () => void;
type SetData = (data: ResearchListScreenData) => void;
type GetData = () => ResearchListScreenData;

@injectable()
export default class ResearchListScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
