import { injectable, inject } from "inversify";
import SnpListScreenController from "@/adapters/controllers/screen-snp-list";
import { ISnpGateway } from "@/adapters/gateways/dna";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class ChangeFilterInteractor {
  _snpListScreenController: SnpListScreenController;
  _snpGateway: ISnpGateway;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(SnpListScreenController)
    snpListScreenController: SnpListScreenController,
    @inject("SnpGateway")
    snpGateway: ISnpGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._snpListScreenController = snpListScreenController;
    this._snpGateway = snpGateway;
    this._sessionGateway = sessionGateway;
  }

  async handle(page: number, search: string, sort: string): Promise<void> {
    this._snpListScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    const snpsPage = await this._snpGateway.list(
      session.tokens!,
      page,
      search,
      sort
    );
    this._snpListScreenController.setData!({ snpsPage });
    this._snpListScreenController.setAsLoaded!();
  }
}
