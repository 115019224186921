import { injectable } from "inversify";
import ReportCreateScreenData from "@/adapters/models/screen-report-create";

type EmptyAction = () => void;
type SetData = (data: ReportCreateScreenData) => void;
type GetData = () => ReportCreateScreenData;

@injectable()
export default class ReportCreateScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
