import { injectable } from "inversify";
import ReportPlusListScreenData from "@/adapters/models/screen-report-plus-list";

type EmptyAction = () => void;
type SetData = (data: ReportPlusListScreenData) => void;
type GetData = () => ReportPlusListScreenData;

@injectable()
export default class ReportPlusListScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
