import { injectable, inject } from "inversify";
import SnpFrequencyListScreenController from "@/adapters/controllers/screen-snp-frequency-list";

@injectable()
export default class ChangeFilterInteractor {
  _snpFrequencyListScreenController: SnpFrequencyListScreenController;

  constructor(
    @inject(SnpFrequencyListScreenController)
    snpFrequencyListScreenController: SnpFrequencyListScreenController
  ) {
    this._snpFrequencyListScreenController = snpFrequencyListScreenController;
  }

  async handle(): Promise<void> {}
}
