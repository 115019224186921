import { injectable } from "inversify";
import PlanEditScreenData from "@/adapters/models/screen-plan-edit";

type EmptyAction = () => void;
type SetData = (data: PlanEditScreenData) => void;
type GetData = () => PlanEditScreenData;

@injectable()
export default class PlanEditScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
