import { injectable, inject } from "inversify";
import BehaviourEditScreenController from "@/adapters/controllers/screen-behaviour-edit";
import { IBehaviourGateway } from "@/adapters/gateways/behaviours";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { IGenosetGateway } from "@/adapters/gateways/dna";

@injectable()
export default class InitBehaviourEditScreenInteractor {
  _behaviourEditScreenController: BehaviourEditScreenController;
  _behaviourGateway: IBehaviourGateway;
  _genosetGateway: IGenosetGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(BehaviourEditScreenController)
    behaviourEditScreenController: BehaviourEditScreenController,
    @inject("BehaviourGateway")
    behaviourGateway: IBehaviourGateway,
    @inject("GenosetGateway")
    genosetGateway: IGenosetGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._behaviourEditScreenController = behaviourEditScreenController;
    this._behaviourGateway = behaviourGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
    this._genosetGateway = genosetGateway;
  }

  async handle(id: string): Promise<void> {
    this._behaviourEditScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    const behaviour = await this._behaviourGateway.retrieve(
      session.tokens!,
      id
    );
    this._behaviourEditScreenController.setData!({ behaviour });
    this._behaviourEditScreenController.setAsLoaded!();
  }
}
