import { injectable, inject } from "inversify";
import GenomemosetListScreenController from "@/adapters/controllers/screen-genomemoset-list";
import { IGenomemosetGateway } from "@/adapters/gateways/dna";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class ChangeFilterInteractor {
  _genomemosetListScreenController: GenomemosetListScreenController;
  _genomemosetGateway: IGenomemosetGateway;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(GenomemosetListScreenController)
    genomemosetListScreenController: GenomemosetListScreenController,
    @inject("GenomemosetGateway")
    genomemosetGateway: IGenomemosetGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._genomemosetListScreenController = genomemosetListScreenController;
    this._genomemosetGateway = genomemosetGateway;
    this._sessionGateway = sessionGateway;
  }

  async handle(page: number, search: string, sort: string): Promise<void> {
    this._genomemosetListScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    const genomemosetsPage = await this._genomemosetGateway.list(
      session.tokens!,
      page,
      search,
      sort
    );
    this._genomemosetListScreenController.setData!({ genomemosetsPage });
    this._genomemosetListScreenController.setAsLoaded!();
  }
}
