import { injectable, inject } from "inversify";
import PlanEditScreenController from "@/adapters/controllers/screen-plan-edit";
import { IPlanGateway } from "@/adapters/gateways/plans";
import { RouterController } from "@/adapters/controllers/router";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class InitPlanEditInteractor {
  _planEditScreenController: PlanEditScreenController;
  _planGateway: IPlanGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(PlanEditScreenController)
    planEditScreenController: PlanEditScreenController,
    @inject("PlanGateway")
    planGateway: IPlanGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._planEditScreenController = planEditScreenController;
    this._planGateway = planGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(id: string): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._planEditScreenController.setAsLoading!();
    const plan = await this._planGateway.retrieve(id, session.tokens!);
    this._planEditScreenController.setData!({ plan });
    this._planEditScreenController.setAsLoaded!();
  }
}
