import { injectable, inject } from "inversify";
import PlanCodeListScreenController from "@/adapters/controllers/screen-plan-code-list";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { IPlanCodeGateway } from "@/adapters/gateways/plans";

@injectable()
export default class InitPlanCodeListInteractor {
  _planCodeListScreenController: PlanCodeListScreenController;
  _planCodeGateway: IPlanCodeGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(PlanCodeListScreenController)
    planCodeListScreenController: PlanCodeListScreenController,
    @inject("PlanCodeGateway")
    planCodeGateway: IPlanCodeGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._planCodeListScreenController = planCodeListScreenController;
    this._planCodeGateway = planCodeGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._planCodeListScreenController.setAsLoading!();
    const plansCodesPage = await this._planCodeGateway.list(session.tokens!);
    this._planCodeListScreenController.setData!({ plansCodesPage });
    this._planCodeListScreenController.setAsLoaded!();
  }
}
