import { injectable, inject } from "inversify";
import BehaviourListScreenController from "@/adapters/controllers/screen-behaviour-list";
import { IBehaviourGateway } from "@/adapters/gateways/behaviours";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class ChangeFilterInteractor {
  _behaviourListScreenController: BehaviourListScreenController;
  _sessionGateway: ISessionGateway;
  _behaviourGateway: IBehaviourGateway;

  constructor(
    @inject(BehaviourListScreenController)
    behaviourListScreenController: BehaviourListScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject("BehaviourGateway")
    behaviourGateway: IBehaviourGateway
  ) {
    this._behaviourListScreenController = behaviourListScreenController;
    this._behaviourGateway = behaviourGateway;
    this._sessionGateway = sessionGateway;
  }

  async handle(page: number, search: string, sort: string): Promise<void> {
    this._behaviourListScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    const behavioursPage = await this._behaviourGateway.list(
      session.tokens!,
      page,
      search,
      sort
    );
    this._behaviourListScreenController.setData!({ behavioursPage });
    this._behaviourListScreenController.setAsLoaded!();
  }
}
