import axios from "axios";
// @ts-ignore
import queryString from "querystring";
import { injectable } from "inversify";

import * as models from "@/domain/users/models";
import * as gateways from "@/adapters/gateways/users";
import * as constants from "@/commons/constants";
import { Tokens } from "@/domain/users/models";

@injectable()
export class CredentialServerGateway implements gateways.ICredentialGateway {
  URL: string = `${constants.SERVER_ADDRESS}/users/credential`;

  list(): Promise<Array<models.Credential>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.Credential>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.Credential): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string): Promise<models.Credential> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(instance: models.Credential): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class UserServerGateway implements gateways.IUserGateway {
  ROOT_URL: string = `${constants.SERVER_ADDRESS}/users`;
  URL: string = `${constants.SERVER_ADDRESS}/users/user`;

  list(
    tokens: Tokens,
    page?: number,
    search?: string,
    sort?: string,
    pageSize?: number
  ): Promise<models.IUserPage> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`, {
          params: { page, search, sort, pageSize },
          headers: tokens.getHeader()
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any, tokens?: Tokens): Promise<Array<models.User>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query, { headers: tokens!.getHeader() })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.User, tokens?: Tokens): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance, { headers: tokens!.getHeader() })
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string, tokens?: Tokens): Promise<models.User> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`, { headers: tokens!.getHeader() })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(instance: models.User, tokens?: Tokens): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance, {
          headers: tokens!.getHeader()
        })
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string, tokens?: Tokens): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`, { headers: tokens!.getHeader() })
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  signIn(data: models.UserSignIn): Promise<models.Tokens> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${constants.SERVER_ADDRESS}/tokens/`,
          queryString.stringify(data as any),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }
        )
        .then(response => {
          resolve(
            new models.Tokens(response.data.access, response.data.refresh)
          );
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  me(tokens: models.Tokens): Promise<models.Credential> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.ROOT_URL}/me/`, {
          headers: tokens.getHeader()
        })
        .then(response => {
          let credential = Object.create(models.Credential.prototype);
          resolve(Object.assign(credential, response.data));
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class SessionGateway implements gateways.ISessionGateway {
  session: models.Session = {};

  set(session: models.Session): void {
    this.session = session;
  }

  get(): models.Session {
    return this.session;
  }
}

@injectable()
export class UserNodeServerGateway implements gateways.IUserNodeGateway {
  URL: string = `${constants.SERVER_ADDRESS}/users/user-node`;

  list(): Promise<Array<models.UserNode>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.UserNode>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.UserNode): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string): Promise<models.UserNode> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(instance: models.UserNode): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class UserNodeLocalGateway implements gateways.IUserNodeGateway {
  KEY: string = "user-node";

  _load(): any {
    const raw = localStorage.getItem(this.KEY);
    if (raw != null) {
      return JSON.parse(raw);
    } else {
      return {};
    }
  }

  _save(data: any) {
    const raw = JSON.stringify(data);
    localStorage.setItem(this.KEY, raw);
  }

  _set(instance: models.UserNode) {
    let data = this._load();
    data[instance.id] = instance;
    this._save(data);
  }

  list(): Promise<Array<models.UserNode>> {
    return new Promise(resolve => {
      const data = this._load();
      let result = [];
      for (let id in data) {
        let instance = data[id];
        result.push(instance);
      }
      resolve(result);
    });
  }

  // TODO
  filter(query: any): Promise<Array<models.UserNode>> {
    return this.list();
  }

  create(instance: models.UserNode): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  retrieve(id: string): Promise<models.UserNode> {
    return new Promise(resolve => {
      const data = this._load();
      resolve(data[id]);
    });
  }

  update(instance: models.UserNode): Promise<void> {
    return new Promise(resolve => {
      this._set(instance);
      resolve();
    });
  }

  delete(id: string): Promise<void> {
    return new Promise(resolve => {
      let data = this._load();
      delete data[id];
      this._save(data);
      resolve();
    });
  }
}
