import { injectable } from "inversify";
import UserEditScreenData from "@/adapters/models/screen-user-edit";

type EmptyAction = () => void;
type SetData = (data: UserEditScreenData) => void;
type GetData = () => UserEditScreenData;

@injectable()
export default class UserEditScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
