import { injectable } from "inversify";
import SnpCreateScreenData from "@/adapters/models/screen-snp-create";

type EmptyAction = () => void;
type SetData = (data: SnpCreateScreenData) => void;
type GetData = () => SnpCreateScreenData;

@injectable()
export default class SnpCreateScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
