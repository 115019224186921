import { RouterController } from "@/adapters/controllers/router";
import BehaviourEditScreenController from "@/adapters/controllers/screen-behaviour-edit";
import { IBehaviourGateway } from "@/adapters/gateways/behaviours";
import { ISessionGateway } from "@/adapters/gateways/users";
import { Behaviour } from "@/domain/behaviours/models";
import { inject, injectable } from "inversify";

@injectable()
export default class SubmitFormInteractor {
  _behaviourEditScreenController: BehaviourEditScreenController;
  _behaviourGateway: IBehaviourGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(BehaviourEditScreenController)
    behaviourEditScreenController: BehaviourEditScreenController,
    @inject("BehaviourGateway")
    behaviourGateway: IBehaviourGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._behaviourEditScreenController = behaviourEditScreenController;
    this._behaviourGateway = behaviourGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(behaviour: Behaviour): Promise<void> {
    this._behaviourEditScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    await this._behaviourGateway.update(session.tokens!, behaviour);
    this._routerController.pushBehaviourList!();
    this._behaviourEditScreenController.setAsLoaded!();
  }
}
