import { injectable, inject } from "inversify";
import SnpListScreenController from "@/adapters/controllers/screen-snp-list";
import { RouterController } from "@/adapters/controllers/router";
import { Snp } from "@/domain/dna/models";

@injectable()
export default class SelectSnpEditInteractor {
  _routerController: RouterController;

  constructor(
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._routerController = routerController;
  }

  async handle(instance: Snp): Promise<void> {
    this._routerController.pushSnpEdit!(instance.id);
  }
}
