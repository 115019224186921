import { injectable, inject } from "inversify";
import SlotListScreenController from "@/adapters/controllers/screen-slot-list";
import { ISlotGateway } from "@/adapters/gateways/slots";
import { RouterController } from "@/adapters/controllers/router";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class InitSlotListInteractor {
  _slotListScreenController: SlotListScreenController;
  _slotGateway: ISlotGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(SlotListScreenController)
    slotListScreenController: SlotListScreenController,
    @inject("SlotGateway")
    slotGateway: ISlotGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._slotListScreenController = slotListScreenController;
    this._slotGateway = slotGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._slotListScreenController.setAsLoading!();
    const slotsPage = await this._slotGateway.list(session.tokens!);
    this._slotListScreenController.setData!({ slotsPage });
    this._slotListScreenController.setAsLoaded!();
  }
}
