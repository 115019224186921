import { injectable, inject } from "inversify";
import RobotViewScreenController from "@/adapters/controllers/screen-robot-view";
import { RobotTest } from "@/domain/robots/models";
import { RouterController } from "@/adapters/controllers/router";
import { IRobotTestGateway } from "@/adapters/gateways/robots";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class SubmitNewRobotTestInteractor {
  _robotViewScreenController: RobotViewScreenController;
  _robotTestGateway: IRobotTestGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(RobotViewScreenController)
    robotViewScreenController: RobotViewScreenController,
    @inject("RobotTestGateway")
    robotTestGateway: IRobotTestGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._robotViewScreenController = robotViewScreenController;
    this._robotTestGateway = robotTestGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(instance: RobotTest): Promise<void> {
    this._robotViewScreenController.setAsLoading!();

    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }

    await this._robotTestGateway.create(session.tokens!, instance);

    this._robotViewScreenController.reload!();
    this._robotViewScreenController.setAsLoaded!();
  }
}
