import { injectable, inject } from "inversify";
import SlotEditScreenController from "@/adapters/controllers/screen-slot-edit";
import { ISlotGateway } from "@/adapters/gateways/slots";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { Slot } from "@/domain/slots/models";

@injectable()
export default class SubmitFormInteractor {
  _slotEditScreenController: SlotEditScreenController;
  _slotGateway: ISlotGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(SlotEditScreenController)
    slotEditScreenController: SlotEditScreenController,
    @inject("SlotGateway")
    slotGateway: ISlotGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._slotEditScreenController = slotEditScreenController;
    this._slotGateway = slotGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(slot: Slot): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._slotEditScreenController.setAsLoading!();
    await this._slotGateway.update(slot, session.tokens!);
    this._slotEditScreenController.setAsLoaded!();
    this._routerController.pushSlotList!();
  }
}
