import * as gateways from "@/adapters/gateways/robots";
import * as constants from "@/commons/constants";
import * as models from "@/domain/robots/models";
import { Tokens } from "@/domain/users/models";
import axios from "axios";
import { injectable } from "inversify";

@injectable()
export class RobotServerGateway implements gateways.IRobotGateway {
  URL: string = `${constants.SERVER_ADDRESS}/robots/robot`;

  list(
    tokens: Tokens,
    page?: number,
    search?: string,
    sort?: string
  ): Promise<models.IRobotPage> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`, {
          params: { page, search, sort },
          headers: tokens.getHeader()
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.Robot>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.Robot, tokens: Tokens): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance, {
          headers: tokens.getHeader()
        })
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(tokens: Tokens, id: string): Promise<models.Robot> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`, {
          headers: tokens.getHeader()
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(tokens: Tokens, instance: models.Robot): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance, {
          headers: tokens.getHeader()
        })
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  duplicate(tokens: Tokens, instance: models.Robot): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${this.URL}/duplicate/`,
          { robot: instance.id },
          { headers: tokens.getHeader() }
        )
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  generateModel(tokens: Tokens, instance: models.Robot): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${this.URL}/generate-model/`,
          { robot: instance.id },
          { headers: tokens.getHeader() }
        )
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  testProcess(tokens: Tokens, instance: models.RobotTest): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${this.URL}/test-process/`,
          { robotTestId: instance.id },
          { headers: tokens.getHeader() }
        )
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class RobotSampleServerGateway implements gateways.IRobotSampleGateway {
  URL: string = `${constants.SERVER_ADDRESS}/robots/robot-sample`;

  list(
    tokens: Tokens,
    robot: models.Robot
  ): Promise<Array<models.RobotSample>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/?robot=${robot.id}`, {
          headers: tokens.getHeader()
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.RobotSample>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(tokens: Tokens, instance: models.RobotSample): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance, {
          headers: tokens.getHeader()
        })
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string): Promise<models.RobotSample> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(tokens: Tokens, instance: models.RobotSample): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${this.URL}/${instance.id}`, instance, {
          headers: tokens.getHeader()
        })
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class RobotTestServerGateway implements gateways.IRobotTestGateway {
  URL: string = `${constants.SERVER_ADDRESS}/robots/robot-test`;

  list(tokens: Tokens, robot: models.Robot): Promise<Array<models.RobotTest>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/?robot=${robot.id}`, {
          headers: tokens.getHeader()
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.RobotTest>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(tokens: Tokens, instance: models.RobotTest): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance, {
          headers: tokens.getHeader()
        })
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string): Promise<models.RobotTest> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(instance: models.RobotTest): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
