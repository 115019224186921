import { injectable, inject } from "inversify";
import BehaviourCreateScreenController from "@/adapters/controllers/screen-behaviour-create";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class InitBehaviourCreateInteractor {
  _behaviourCreateScreenController: BehaviourCreateScreenController;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(BehaviourCreateScreenController)
    behaviourCreateScreenController: BehaviourCreateScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._behaviourCreateScreenController = behaviourCreateScreenController;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
  }
}
