import { injectable, inject } from "inversify";
import CustomReportViewScreenController from "@/adapters/controllers/screen-custom-report-view";

@injectable()
export default class InitCustomReportViewInteractor {
  _customReportViewScreenController: CustomReportViewScreenController;

  constructor(
    @inject(CustomReportViewScreenController)
    customReportViewScreenController: CustomReportViewScreenController
  ) {
    this._customReportViewScreenController = customReportViewScreenController;
  }

  async handle(): Promise<void> {}
}
