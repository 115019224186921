import { injectable, inject } from "inversify";
import GenosetEditScreenController from "@/adapters/controllers/screen-genoset-edit";
import { IGenosetGateway } from "@/adapters/gateways/dna";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { Genoset } from "@/domain/dna/models";

@injectable()
export default class SubmitFormInteractor {
  _genosetEditScreenController: GenosetEditScreenController;
  _genosetGateway: IGenosetGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(GenosetEditScreenController)
    genosetEditScreenController: GenosetEditScreenController,
    @inject("GenosetGateway")
    genosetGateway: IGenosetGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._genosetEditScreenController = genosetEditScreenController;
    this._genosetGateway = genosetGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(genoset: Genoset): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._genosetEditScreenController.setAsLoading!();
    await this._genosetGateway.update(genoset, session.tokens!);
    this._genosetEditScreenController.setAsLoaded!();
    this._routerController.pushGenosetList!();
  }
}
