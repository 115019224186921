import { injectable } from "inversify";
import ReportPlusCreateScreenData from "@/adapters/models/screen-report-plus-create";

type EmptyAction = () => void;
type SetData = (data: ReportPlusCreateScreenData) => void;
type GetData = () => ReportPlusCreateScreenData;

@injectable()
export default class ReportPlusCreateScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
