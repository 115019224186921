import { Entity } from "@/commons/models";

export class Behaviour extends Entity {
  constructor(
    id?: string,
    createdAt?: Date,
    modifiedAt?: Date,
    disabledAt?: Date
  ) {
    super(id, createdAt, modifiedAt, disabledAt);
  }
}

export interface IBehaviourPage {
  count: number;
  results: Behaviour[];
}

export interface IBehaviourUpdate {
  id: string;
  summaryEnUs: string;
  summaryPtBr: string;
  summaryEsEs: string;
  isPublished: boolean;
}

export class UserBehaviour extends Entity {
  constructor(
    id?: string,
    createdAt?: Date,
    modifiedAt?: Date,
    disabledAt?: Date
  ) {
    super(id, createdAt, modifiedAt, disabledAt);
  }
}

export class BehaviourType extends Entity {
  constructor(
    id?: string,
    createdAt?: Date,
    modifiedAt?: Date,
    disabledAt?: Date
  ) {
    super(id, createdAt, modifiedAt, disabledAt);
  }
}
