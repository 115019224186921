import { injectable, inject } from "inversify";
import ReportPlusCreateScreenController from "@/adapters/controllers/screen-report-plus-create";

@injectable()
export default class InitReportPlusCreateInteractor {
  _reportPlusCreateScreenController: ReportPlusCreateScreenController;

  constructor(
    @inject(ReportPlusCreateScreenController)
    reportPlusCreateScreenController: ReportPlusCreateScreenController
  ) {
    this._reportPlusCreateScreenController = reportPlusCreateScreenController;
  }

  async handle(): Promise<void> {}
}
