import { RouterController } from "@/adapters/controllers/router";
import RobotViewScreenController from "@/adapters/controllers/screen-robot-view";
import { IRobotSampleGateway } from "@/adapters/gateways/robots";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RobotSample } from "@/domain/robots/models";
import { inject, injectable } from "inversify";

@injectable()
export default class SelectRobotSampleSpecificityInteractor {
  _robotViewScreenController: RobotViewScreenController;
  _robotSampleGateway: IRobotSampleGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(RobotViewScreenController)
    robotViewScreenController: RobotViewScreenController,
    @inject("RobotSampleGateway")
    robotSampleGateway: IRobotSampleGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._robotViewScreenController = robotViewScreenController;
    this._robotSampleGateway = robotSampleGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(instance: RobotSample): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    await this._robotSampleGateway.update(session.tokens!, instance);
  }
}
