import axios from "axios";
import { injectable } from "inversify";

import * as gateways from "@/adapters/gateways/behaviours";
import * as constants from "@/commons/constants";
import * as models from "@/domain/behaviours/models";
import { Tokens } from "@/domain/users/models";

@injectable()
export class BehaviourServerGateway implements gateways.IBehaviourGateway {
  URL: string = `${constants.SERVER_ADDRESS}/behaviours/behaviour`;

  list(
    tokens: Tokens,
    page?: number,
    search?: string,
    sort?: string
  ): Promise<models.IBehaviourPage> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`, {
          params: { page, search, sort },
          headers: tokens.getHeader()
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.Behaviour>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.Behaviour, tokens?: Tokens): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance, {
          headers: tokens!.getHeader()
        })
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(tokens: Tokens, id: string): Promise<models.Behaviour> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`, {
          headers: tokens.getHeader()
        })
        .then(response => {
          const type = Object.create(models.Behaviour.prototype);
          resolve(Object.assign(type, response.data));
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(tokens: Tokens, instance: models.Behaviour): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance, {
          headers: tokens.getHeader()
        })
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class UserBehaviourServerGateway
  implements gateways.IUserBehaviourGateway {
  URL: string = `${constants.SERVER_ADDRESS}/behaviours/user-behaviour`;

  list(): Promise<Array<models.UserBehaviour>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.UserBehaviour>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.UserBehaviour): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string): Promise<models.UserBehaviour> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(instance: models.UserBehaviour): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

@injectable()
export class BehaviourTypeServerGateway
  implements gateways.IBehaviourTypeGateway {
  URL: string = `${constants.SERVER_ADDRESS}/behaviours/behaviour-type`;

  list(): Promise<Array<models.BehaviourType>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  filter(query: any): Promise<Array<models.BehaviourType>> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, query)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create(instance: models.BehaviourType): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.URL}/`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  retrieve(id: string): Promise<models.BehaviourType> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.URL}/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  update(instance: models.BehaviourType): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL}/${instance.id}`, instance)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.URL}/${id}`)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
