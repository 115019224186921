import { injectable, inject } from "inversify";
import SlotListScreenController from "@/adapters/controllers/screen-slot-list";
import { ISlotGateway } from "@/adapters/gateways/slots";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class ChangeFilterInteractor {
  _slotListScreenController: SlotListScreenController;
  _slotGateway: ISlotGateway;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(SlotListScreenController)
    slotListScreenController: SlotListScreenController,
    @inject("SlotGateway")
    slotGateway: ISlotGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._slotListScreenController = slotListScreenController;
    this._slotGateway = slotGateway;
    this._sessionGateway = sessionGateway;
  }

  async handle(page: number, search: string, sort: string): Promise<void> {
    this._slotListScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    const slotsPage = await this._slotGateway.list(
      session.tokens!,
      page,
      search,
      sort
    );
    this._slotListScreenController.setData!({ slotsPage });
    this._slotListScreenController.setAsLoaded!();
  }
}
