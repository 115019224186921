import { injectable, inject } from "inversify";
import CustomReportListScreenController from "@/adapters/controllers/screen-custom-report-list";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { ICustomReportGateway } from "@/adapters/gateways/reports";

@injectable()
export default class InitCustomReportListInteractor {
  _customReportListScreenController: CustomReportListScreenController;

  _sessionGateway: ISessionGateway;
  _routerController: RouterController;
  _customReportGateway: ICustomReportGateway;

  constructor(
    @inject(CustomReportListScreenController)
    customReportScreenController: CustomReportListScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject("CustomReportGateway")
    customReportGateway: ICustomReportGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._customReportListScreenController = customReportScreenController;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
    this._customReportGateway = customReportGateway;
  }

  async handle(): Promise<void> {
    this._customReportListScreenController.setAsLoading!();
    let session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }

    const customReportsPage = await this._customReportGateway.list(
      session.tokens!
    );
    this._customReportListScreenController.setData!({ customReportsPage });
    this._customReportListScreenController.setAsLoaded!();
  }
}
