import ResearchDetailsScreenController from "@/adapters/controllers/screen-research-details";
import { IResearchGateway } from "@/adapters/gateways/researches";
import { ISessionGateway } from "@/adapters/gateways/users";
import { inject, injectable } from "inversify";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class SelectCellInteractor {
  _researchDetailsScreenController: ResearchDetailsScreenController;
  _researchGateway: IResearchGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(ResearchDetailsScreenController)
    researchDetailsScreenController: ResearchDetailsScreenController,
    @inject("ResearchGateway")
    researchGateway: IResearchGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._researchDetailsScreenController = researchDetailsScreenController;
    this._researchGateway = researchGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(
    researchId: string,
    cellId: string,
    type: number,
    offset: number,
    size: number
  ): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._researchDetailsScreenController.setAsLoading!();
    const data = await this._researchGateway.values(
      researchId,
      cellId,
      type,
      offset,
      size,
      session.tokens!
    );
    this._researchDetailsScreenController.showCellResults!(data);
    this._researchDetailsScreenController.setAsLoaded!();
  }
}
