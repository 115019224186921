import { injectable } from "inversify";
import GenosetCreateScreenData from "@/adapters/models/screen-genoset-create";

type EmptyAction = () => void;
type SetData = (data: GenosetCreateScreenData) => void;
type GetData = () => GenosetCreateScreenData;

@injectable()
export default class GenosetCreateScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
