import CustomReportView_InitCustomReportViewInteractor from "@/business/custom-report-view/init-custom-report-view";
import CustomReportViewScreenController from "@/adapters/controllers/screen-custom-report-view";
import CustomReportList_SelectCreateInteractor from "@/business/custom-report-list/select-create";
import CustomReportList_SelectCustomReportViewInteractor from "@/business/custom-report-list/select-custom-report-view";
import CustomReportList_SelectCustomReportEditInteractor from "@/business/custom-report-list/select-custom-report-edit";
import CustomReportList_InitCustomReportListInteractor from "@/business/custom-report-list/init-custom-report-list";
import CustomReportList_ChangeFilterInteractor from "@/business/custom-report-list/change-filter";
import CustomReportListScreenController from "@/adapters/controllers/screen-custom-report-list";
import CustomReportEdit_SubmitFormInteractor from "@/business/custom-report-edit/submit-form";
import CustomReportEdit_InitCustomReportEditInteractor from "@/business/custom-report-edit/init-custom-report-edit";
import CustomReportEditScreenController from "@/adapters/controllers/screen-custom-report-edit";
import CustomReportCreate_SubmitFormInteractor from "@/business/custom-report-create/submit-form";
import CustomReportCreate_InitCustomReportCreateInteractor from "@/business/custom-report-create/init-custom-report-create";
import CustomReportCreateScreenController from "@/adapters/controllers/screen-custom-report-create";
import GenotypeList_SelectUpdateStatisticsInteractor from "@/business/genotype-list/select-update-statistics";
import ReportPlusView_InitReportPlusViewInteractor from "@/business/report-plus-view/init-report-plus-view";
import ReportPlusViewScreenController from "@/adapters/controllers/screen-report-plus-view";
import ReportPlusList_SelectCreateInteractor from "@/business/report-plus-list/select-create";
import ReportPlusList_SelectReportPlusViewInteractor from "@/business/report-plus-list/select-report-plus-view";
import ReportPlusList_SelectReportPlusEditInteractor from "@/business/report-plus-list/select-report-plus-edit";
import ReportPlusList_InitReportPlusListInteractor from "@/business/report-plus-list/init-report-plus-list";
import ReportPlusList_ChangeFilterInteractor from "@/business/report-plus-list/change-filter";
import ReportPlusListScreenController from "@/adapters/controllers/screen-report-plus-list";
import ReportPlusEdit_SubmitFormInteractor from "@/business/report-plus-edit/submit-form";
import ReportPlusEdit_InitReportPlusEditInteractor from "@/business/report-plus-edit/init-report-plus-edit";
import ReportPlusEditScreenController from "@/adapters/controllers/screen-report-plus-edit";
import ReportPlusCreate_SubmitFormInteractor from "@/business/report-plus-create/submit-form";
import ReportPlusCreate_InitReportPlusCreateInteractor from "@/business/report-plus-create/init-report-plus-create";
import ReportPlusCreateScreenController from "@/adapters/controllers/screen-report-plus-create";
import RobotList_SelectRobotDuplicateInteractor from "@/business/robot-list/select-robot-duplicate";
import RobotView_SelectRobotSampleSpecificityInteractor from "@/business/robot-view/select-robot-sample-specificity";
import CorrelationView_SelectUserReportProcessAllInteractor from "@/business/correlation-view/select-user-report-process-all";
import RobotView_SelectRobotTestProcessAllInteractor from "@/business/robot-view/select-robot-test-process-all";
import RobotView_RefreshProgressInteractor from "@/business/robot-view/refresh-progress";
import RobotView_SelectRobotTestProcessInteractor from "@/business/robot-view/select-robot-test-process";
import RobotView_SelectRobotEditInteractor from "@/business/robot-view/select-robot-edit";
import RobotView_SelectGenerateModelInteractor from "@/business/robot-view/select-generate-model";
import RobotView_SubmitNewRobotTestInteractor from "@/business/robot-view/submit-new-robot-test";
import RobotView_SubmitNewRobotSampleInteractor from "@/business/robot-view/submit-new-robot-sample";
import RobotView_InitRobotViewInteractor from "@/business/robot-view/init-robot-view";
import RobotList_SelectCreateInteractor from "@/business/robot-list/select-create";
import RobotList_SelectRobotViewInteractor from "@/business/robot-list/select-robot-view";
import RobotList_SelectRobotEditInteractor from "@/business/robot-list/select-robot-edit";
import RobotList_InitRobotListInteractor from "@/business/robot-list/init-robot-list";
import RobotList_ChangeFilterInteractor from "@/business/robot-list/change-filter";
import RobotEdit_SubmitFormInteractor from "@/business/robot-edit/submit-form";
import RobotEdit_InitRobotEditInteractor from "@/business/robot-edit/init-robot-edit";
import RobotCreate_SubmitFormInteractor from "@/business/robot-create/submit-form";
import RobotCreate_InitRobotCreateInteractor from "@/business/robot-create/init-robot-create";
import RobotViewScreenController from "@/adapters/controllers/screen-robot-view";
import RobotEditScreenController from "@/adapters/controllers/screen-robot-edit";
import RobotCreateScreenController from "@/adapters/controllers/screen-robot-create";
import RobotListScreenController from "@/adapters/controllers/screen-robot-list";
import * as robotsGateways from "@/application/gateways/robots";
import SlotEdit_InitSlotEditInteractor from "@/business/slot-edit/init-slot-edit";
import SlotEdit_SubmitFormInteractor from "@/business/slot-edit/submit-form";
import SlotCreate_InitSlotCreateInteractor from "@/business/slot-create/init-slot-create";
import SlotCreate_SubmitFormInteractor from "@/business/slot-create/submit-form";
import SlotList_SelectSlotEditInteractor from "@/business/slot-list/select-slot-edit";
import SlotList_SelectCreateInteractor from "@/business/slot-list/select-create";
import SlotList_ChangeFilterInteractor from "@/business/slot-list/change-filter";
import SlotList_InitSlotListInteractor from "@/business/slot-list/init-slot-list";
import SlotEditScreenController from "@/adapters/controllers/screen-slot-edit";
import SlotCreateScreenController from "@/adapters/controllers/screen-slot-create";
import SlotListScreenController from "@/adapters/controllers/screen-slot-list";
import * as slotsGateways from "@/application/gateways/slots";
import * as snpsFrequenciesGateways from "@/application/gateways/snps-frequencies";
import SnpFrequencyList_SelectSnpFrequencyEditInteractor from "@/business/snp-frequency-list/select-snp-frequency-edit";
import SnpFrequencyList_SelectCreateInteractor from "@/business/snp-frequency-list/select-create";
import SnpFrequencyList_ChangeFilterInteractor from "@/business/snp-frequency-list/change-filter";
import SnpFrequencyList_InitSnpFrequencyListScreenInteractor from "@/business/snp-frequency-list/init-snp-frequency-list-screen";
import SnpFrequencyListScreenController from "@/adapters/controllers/screen-snp-frequency-list";
import CorrelationView_SelectCorrelationEditInteractor from "@/business/correlation-view/select-correlation-edit";
import CorrelationView_SelectUserReportProcessInteractor from "@/business/correlation-view/select-user-report-process";
import CorrelationView_SubmitNewUserReportInteractor from "@/business/correlation-view/submit-new-user-report";
import CorrelationView_SelectGenerateModelInteractor from "@/business/correlation-view/select-generate-model";
import CorrelationView_SelectDeleteGroupUserInteractor from "@/business/correlation-view/select-delete-group-user";
import CorrelationView_SubmitNewGroupUserInteractor from "@/business/correlation-view/submit-new-group-user";
import CorrelationView_FindUserInteractor from "@/business/correlation-view/find-user";
import CorrelationView_SubmitSwapGroupsInteractor from "@/business/correlation-view/submit-swap-groups";
import CorrelationView_SubmitEditGroupInteractor from "@/business/correlation-view/submit-edit-group";
import CorrelationView_SubmitNewGroupInteractor from "@/business/correlation-view/submit-new-group";
import CorrelationView_InitCorrelationViewInteractor from "@/business/correlation-view/init-correlation-view";
import CorrelationViewScreenController from "@/adapters/controllers/screen-correlation-view";
import CorrelationList_SelectCorrelationViewInteractor from "@/business/correlation-list/select-correlation-view";
import CorrelationEdit_InitCorrelationEditInteractor from "@/business/correlation-edit/init-correlation-edit";
import CorrelationEdit_SubmitFormInteractor from "@/business/correlation-edit/submit-form";
import CorrelationCreate_SubmitFormInteractor from "@/business/correlation-create/submit-form";
import CorrelationCreate_InitCorrelationCreateInteractor from "@/business/correlation-create/init-correlation-create";
import CorrelationEditScreenController from "@/adapters/controllers/screen-correlation-edit";
import CorrelationCreateScreenController from "@/adapters/controllers/screen-correlation-create";
import * as correlationsGateways from "@/application/gateways/correlations";
import CorrelationList_SelectCreateInteractor from "@/business/correlation-list/select-create";
import CorrelationList_SelectCorrelationEditInteractor from "@/business/correlation-list/select-correlation-edit";
import CorrelationList_ChangeFilterInteractor from "@/business/correlation-list/change-filter";
import CorrelationList_InitCorrelationListScreenInteractor from "@/business/correlation-list/init-correlation-list-screen";
import CorrelationListScreenController from "@/adapters/controllers/screen-correlation-list";
import ResearchDetails_SelectResearchEditInteractor from "@/business/research-details/select-research-edit";
import ResearchDetails_SelectResearchProcessInteractor from "@/business/research-details/select-research-process";
import GenomemosetEdit_InitGenomemosetEditInteractor from "@/business/genomemoset-edit/init-genomemoset-edit";
import GenomemosetEdit_SubmitFormInteractor from "@/business/genomemoset-edit/submit-form";
import GenomemosetCreate_SubmitFormInteractor from "@/business/genomemoset-create/submit-form";
import GenomemosetCreate_InitGenomemosetCreateInteractor from "@/business/genomemoset-create/init-genomemoset-create";
import GenomemosetList_SelectGenomemosetEditInteractor from "@/business/genomemoset-list/select-genomemoset-edit";
import GenomemosetList_SelectCreateInteractor from "@/business/genomemoset-list/select-create";
import GenomemosetList_InitGenomemosetListInteractor from "@/business/genomemoset-list/init-genomemoset-list";
import GenomemosetList_ChangeFilterInteractor from "@/business/genomemoset-list/change-filter";
import GenomemosetEditScreenController from "@/adapters/controllers/screen-genomemoset-edit";
import GenomemosetListScreenController from "@/adapters/controllers/screen-genomemoset-list";
import GenomemosetCreateScreenController from "@/adapters/controllers/screen-genomemoset-create";
import * as administrationGateways from "@/application/gateways/administration";
import Overview_InitOverviewInteractor from "@/business/overview/init-overview";
import * as webhooksGateways from "@/application/gateways/webhooks";
import PlanCodeCreate_SubmitFormInteractor from "@/business/plan-code-create/submit-form";
import PlanCodeCreate_InitPlanCodeCreateInteractor from "@/business/plan-code-create/init-plan-code-create";
import PlanCodeList_SelectCreateInteractor from "@/business/plan-code-list/select-create";
import PlanCodeList_ChangeFilterInteractor from "@/business/plan-code-list/change-filter";
import PlanCodeList_InitPlanCodeListInteractor from "@/business/plan-code-list/init-plan-code-list";
import PlanCodeCreateScreenController from "@/adapters/controllers/screen-plan-code-create";
import * as workersGateways from "@/application/gateways/workers";
import PlanCodeListScreenController from "@/adapters/controllers/screen-plan-code-list";
import ResearchDetails_SelectCellInteractor from "@/business/research-details/select-cell";
import ResearchDetails_InitResearchDetailsInteractor from "@/business/research-details/init-research-details";
import ResearchEdit_InitResearchEditInteractor from "@/business/research-edit/init-research-edit";
import ResearchEdit_SubmitFormInteractor from "@/business/research-edit/submit-form";
import ResearchCreate_SubmitFormInteractor from "@/business/research-create/submit-form";
import ResearchCreate_InitResearchCreateInteractor from "@/business/research-create/init-research-create";
import ResearchList_SelectResearchDetailsInteractor from "@/business/research-list/select-research-details";
import ResearchList_SelectResearchEditInteractor from "@/business/research-list/select-research-edit";
import ResearchList_SelectCreateInteractor from "@/business/research-list/select-create";
import ResearchList_ChangeFilterInteractor from "@/business/research-list/change-filter";
import ResearchList_InitResearchListInteractor from "@/business/research-list/init-research-list";
import ResearchDetailsScreenController from "@/adapters/controllers/screen-research-details";
import ResearchEditScreenController from "@/adapters/controllers/screen-research-edit";
import ResearchCreateScreenController from "@/adapters/controllers/screen-research-create";
import ResearchListScreenController from "@/adapters/controllers/screen-research-list";
import * as researchesGateways from "@/application/gateways/researches";
import UserEdit_InitUserEditInteractor from "@/business/user-edit/init-user-edit";
import UserEdit_SubmitFormInteractor from "@/business/user-edit/submit-form";
import UserCreate_SubmitFormInteractor from "@/business/user-create/submit-form";
import UserCreate_InitUserCreateInteractor from "@/business/user-create/init-user-create";
import UserList_SelectUserEditInteractor from "@/business/user-list/select-user-edit";
import UserList_SelectCreateInteractor from "@/business/user-list/select-create";
import UserList_InitUserListInteractor from "@/business/user-list/init-user-list";
import UserList_ChangeFilterInteractor from "@/business/user-list/change-filter";
import UserEditScreenController from "@/adapters/controllers/screen-user-edit";
import UserCreateScreenController from "@/adapters/controllers/screen-user-create";
import UserListScreenController from "@/adapters/controllers/screen-user-list";
import PlanCreate_InitPlanCreateInteractor from "@/business/plan-create/init-plan-create";
import PlanCreate_SubmitFormInteractor from "@/business/plan-create/submit-form";
import PlanEdit_SubmitFormInteractor from "@/business/plan-edit/submit-form";
import PlanEdit_InitPlanEditInteractor from "@/business/plan-edit/init-plan-edit";
import PlanList_SelectPlanEditInteractor from "@/business/plan-list/select-plan-edit";
import PlanList_SelectCreateInteractor from "@/business/plan-list/select-create";
import PlanList_InitPlanListInteractor from "@/business/plan-list/init-plan-list";
import PlanList_ChangeFilterInteractor from "@/business/plan-list/change-filter";
import PlanEditScreenController from "@/adapters/controllers/screen-plan-edit";
import PlanCreateScreenController from "@/adapters/controllers/screen-plan-create";
import PlanListScreenController from "@/adapters/controllers/screen-plan-list";
import ReportCreate_InitReportCreateInteractor from "@/business/report-create/init-report-create";
import ReportCreate_SubmitFormInteractor from "@/business/report-create/submit-form";
import ReportEdit_SubmitFormInteractor from "@/business/report-edit/submit-form";
import ReportEdit_InitReportEditInteractor from "@/business/report-edit/init-report-edit";
import ReportList_SelectCreateInteractor from "@/business/report-list/select-create";
import ReportList_SelectReportEditInteractor from "@/business/report-list/select-report-edit";
import ReportList_InitReportListInteractor from "@/business/report-list/init-report-list";
import ReportList_ChangeFilterInteractor from "@/business/report-list/change-filter";
import * as plansGateways from "@/application/gateways/plans";
import * as reportsGateways from "@/application/gateways/reports";
import ReportEditScreenController from "@/adapters/controllers/screen-report-edit";
import ReportCreateScreenController from "@/adapters/controllers/screen-report-create";
import ReportListScreenController from "@/adapters/controllers/screen-report-list";
import BehaviourList_SelectCreateInteractor from "@/business/behaviour-list/select-create";
import BehaviourCreate_SubmitFormInteractor from "@/business/behaviour-create/submit-form";
import BehaviourCreate_InitBehaviourCreateInteractor from "@/business/behaviour-create/init-behaviour-create";
import BehaviourCreateScreenController from "@/adapters/controllers/screen-behaviour-create";
import GenotypeEdit_SubmitFormInteractor from "@/business/genotype-edit/submit-form";
import GenotypeEdit_InitGenotypeEditInteractor from "@/business/genotype-edit/init-genotype-edit";
import GenotypeEditScreenController from "@/adapters/controllers/screen-genotype-edit";
import GenotypeCreate_SubmitFormInteractor from "@/business/genotype-create/submit-form";
import GenotypeCreate_InitGenotypeCreateInteractor from "@/business/genotype-create/init-genotype-create";
import GenotypeCreateScreenController from "@/adapters/controllers/screen-genotype-create";
import GenotypeList_SelectGenotypeEditInteractor from "@/business/genotype-list/select-genotype-edit";
import GenotypeList_SelectCreateInteractor from "@/business/genotype-list/select-create";
import GenotypeList_InitGenotypeListInteractor from "@/business/genotype-list/init-genotype-list";
import GenotypeList_ChangeFilterInteractor from "@/business/genotype-list/change-filter";
import GenotypeListScreenController from "@/adapters/controllers/screen-genotype-list";
import SnpList_ChangeFilterInteractor from "@/business/snp-list/change-filter";
import SnpCreate_SubmitFormInteractor from "@/business/snp-create/submit-form";
import SnpEdit_SubmitFormInteractor from "@/business/snp-edit/submit-form";
import SnpCreate_InitSnpCreateInteractor from "@/business/snp-create/init-snp-create";
import SnpEdit_InitSnpEditInteractor from "@/business/snp-edit/init-snp-edit";
import SnpList_SelectSnpEditInteractor from "@/business/snp-list/select-snp-edit";
import SnpList_SelectCreateInteractor from "@/business/snp-list/select-create";
import SnpList_InitSnpListInteractor from "@/business/snp-list/init-snp-list";
import SnpCreateScreenController from "@/adapters/controllers/screen-snp-create";
import SnpEditScreenController from "@/adapters/controllers/screen-snp-edit";
import SnpListScreenController from "@/adapters/controllers/screen-snp-list";
import GenosetEdit_SubmitFormInteractor from "@/business/genoset-edit/submit-form";
import GenosetList_SelectGenosetEditInteractor from "@/business/genoset-list/select-genoset-edit";
import GenosetEdit_InitGenosetEditInteractor from "@/business/genoset-edit/init-genoset-edit";
import GenosetList_SelectCreateInteractor from "@/business/genoset-list/select-create";
import GenosetCreate_InitGenosetCreateInteractor from "@/business/genoset-create/init-genoset-create";
import GenosetCreate_SubmitFormInteractor from "@/business/genoset-create/submit-form";
import GenosetEditScreenController from "@/adapters/controllers/screen-genoset-edit";
import GenosetCreateScreenController from "@/adapters/controllers/screen-genoset-create";
import GenosetList_ChangeFilterInteractor from "@/business/genoset-list/change-filter";
import GenosetList_InitGenosetListInteractor from "@/business/genoset-list/init-genoset-list";
import GenosetListScreenController from "@/adapters/controllers/screen-genoset-list";
import BehaviourEdit_SubmitFormInteractor from "@/business/behaviour-edit/submit-form";
import BehaviourList_SelectBehaviourEditInteractor from "@/business/behaviour-list/select-behaviour-edit";
import BehaviourListScreenController from "@/adapters/controllers/screen-behaviour-list";
import BehaviourEdit_InitBehaviourEditScreenInteractor from "@/business/behaviour-edit/init-behaviour-edit-screen";
import BehaviourEditScreenController from "@/adapters/controllers/screen-behaviour-edit";
import BehaviourList_ChangeFilterInteractor from "@/business/behaviour-list/change-filter";
import BehaviourList_InitBehaviourListInteractor from "@/business/behaviour-list/init-behaviour-list-screen";
import Login_InitLoginScreenInteractor from "@/business/login/init-login-screen";
import * as behavioursGateways from "@/application/gateways/behaviours";
import * as dnaGateways from "@/application/gateways/dna";
import OverviewScreenController from "@/adapters/controllers/screen-overview";
import * as uploadsGateways from "@/application/gateways/uploads";
import Login_SubmitLoginInteractor from "@/business/login/submit-login";
import LoginScreenController from "@/adapters/controllers/screen-login";
import * as usersGateways from "@/application/gateways/users";
import { Container } from "inversify";
import { RouterController } from "@/adapters/controllers/router";
import { Localization } from "@/commons/localization";

const container = new Container();

//{ GATEWAYS
container
  .bind("CredentialGateway")
  .to(usersGateways.CredentialServerGateway)
  .inSingletonScope();
container
  .bind("RawDataGateway")
  .to(uploadsGateways.RawDataServerGateway)
  .inSingletonScope();
container
  .bind("UserGateway")
  .to(usersGateways.UserServerGateway)
  .inSingletonScope();
container
  .bind("SessionGateway")
  .to(usersGateways.SessionGateway)
  .inSingletonScope();
container
  .bind("GenosetGateway")
  .to(dnaGateways.GenosetServerGateway)
  .inSingletonScope();
container
  .bind("SnpGateway")
  .to(dnaGateways.SnpServerGateway)
  .inSingletonScope();
container
  .bind("GenotypeGateway")
  .to(dnaGateways.GenotypeServerGateway)
  .inSingletonScope();
container
  .bind("BehaviourGateway")
  .to(behavioursGateways.BehaviourServerGateway)
  .inSingletonScope();
container
  .bind("UserBehaviourGateway")
  .to(behavioursGateways.UserBehaviourServerGateway)
  .inSingletonScope();
container
  .bind("BehaviourTypeGateway")
  .to(behavioursGateways.BehaviourTypeServerGateway)
  .inSingletonScope();
container
  .bind("ReportGateway")
  .to(reportsGateways.ReportServerGateway)
  .inSingletonScope();
container
  .bind("ReportVariantGateway")
  .to(reportsGateways.ReportVariantServerGateway)
  .inSingletonScope();
container
  .bind("PlanGateway")
  .to(plansGateways.PlanServerGateway)
  .inSingletonScope();
container
  .bind("ReportPlanPermissionGateway")
  .to(reportsGateways.ReportPlanPermissionServerGateway)
  .inSingletonScope();
container
  .bind("UserGenosetGateway")
  .to(dnaGateways.UserGenosetServerGateway)
  .inSingletonScope();
container
  .bind("UserGenotypeGateway")
  .to(dnaGateways.UserGenotypeServerGateway)
  .inSingletonScope();
container
  .bind("FigureGateway")
  .to(uploadsGateways.FigureServerGateway)
  .inSingletonScope();
container
  .bind("ResearchGateway")
  .to(researchesGateways.ResearchServerGateway)
  .inSingletonScope();
container
  .bind("DataSourceGateway")
  .to(researchesGateways.DataSourceServerGateway)
  .inSingletonScope();
container
  .bind("ResearchDataSourceGateway")
  .to(researchesGateways.ResearchDataSourceServerGateway)
  .inSingletonScope();
container
  .bind("ResearchResultColumnGateway")
  .to(researchesGateways.ResearchResultColumnServerGateway)
  .inSingletonScope();
container
  .bind("ResearchResultRowGateway")
  .to(researchesGateways.ResearchResultRowServerGateway)
  .inSingletonScope();
container
  .bind("ResearchResultCellGateway")
  .to(researchesGateways.ResearchResultCellServerGateway)
  .inSingletonScope();
container
  .bind("ResearchResultCellUserGateway")
  .to(researchesGateways.ResearchResultCellUserServerGateway)
  .inSingletonScope();
container
  .bind("ResearchResultCellEntryGateway")
  .to(researchesGateways.ResearchResultCellEntryServerGateway)
  .inSingletonScope();
container
  .bind("GlobalSnpVariantRateGateway")
  .to(dnaGateways.GlobalSnpVariantRateServerGateway)
  .inSingletonScope();
container
  .bind(dnaGateways.GlobalSnpVariantRateLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("GlobalSnpGateway")
  .to(dnaGateways.GlobalSnpServerGateway)
  .inSingletonScope();
container
  .bind(dnaGateways.GlobalSnpLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("PlanCodeGateway")
  .to(plansGateways.PlanCodeServerGateway)
  .inSingletonScope();
container
  .bind("WorkerProcessGateway")
  .to(workersGateways.WorkerProcessServerGateway)
  .inSingletonScope();
container
  .bind(workersGateways.WorkerProcessLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("WorkerTaskGateway")
  .to(workersGateways.WorkerTaskServerGateway)
  .inSingletonScope();
container
  .bind(workersGateways.WorkerTaskLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("EduzzTransactionGateway")
  .to(webhooksGateways.EduzzTransactionServerGateway)
  .inSingletonScope();
container
  .bind(webhooksGateways.EduzzTransactionLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("AdministrationGateway")
  .to(administrationGateways.AdministrationGateway)
  .inSingletonScope();
container
  .bind("UserNodeGateway")
  .to(usersGateways.UserNodeServerGateway)
  .inSingletonScope();
container
  .bind(usersGateways.UserNodeLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("GenomemosetGateway")
  .to(dnaGateways.GenomemosetServerGateway)
  .inSingletonScope();
container
  .bind("ResearchDataSourceVolunteerGateway")
  .to(researchesGateways.ResearchDataSourceVolunteerServerGateway)
  .inSingletonScope();
container
  .bind(researchesGateways.ResearchDataSourceVolunteerLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("CorrelationGateway")
  .to(correlationsGateways.CorrelationServerGateway)
  .inSingletonScope();
container
  .bind("CorrelationGroupGateway")
  .to(correlationsGateways.CorrelationGroupServerGateway)
  .inSingletonScope();
container
  .bind("CorrelationGroupUserGateway")
  .to(correlationsGateways.CorrelationGroupUserServerGateway)
  .inSingletonScope();
container
  .bind("CorrelationControlResultsGateway")
  .to(correlationsGateways.CorrelationControlResultsServerGateway)
  .inSingletonScope();
container
  .bind(correlationsGateways.CorrelationControlResultsLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("CorrelationGroupResultsGateway")
  .to(correlationsGateways.CorrelationGroupResultsServerGateway)
  .inSingletonScope();
container
  .bind(correlationsGateways.CorrelationGroupResultsLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("CorrelationUserReportGateway")
  .to(correlationsGateways.CorrelationUserReportServerGateway)
  .inSingletonScope();
container
  .bind("CorrelationUserReportResultsGateway")
  .to(correlationsGateways.CorrelationUserReportResultsServerGateway)
  .inSingletonScope();
container
  .bind("SnpFrequencyGateway")
  .to(snpsFrequenciesGateways.SnpFrequencyServerGateway)
  .inSingletonScope();
container
  .bind("SnpFrequencyReportGateway")
  .to(snpsFrequenciesGateways.SnpFrequencyReportServerGateway)
  .inSingletonScope();
container
  .bind("SnpInfoGateway")
  .to(snpsFrequenciesGateways.SnpInfoServerGateway)
  .inSingletonScope();
container
  .bind("SlotGateway")
  .to(slotsGateways.SlotServerGateway)
  .inSingletonScope();
container
  .bind("SlotUserGateway")
  .to(slotsGateways.SlotUserServerGateway)
  .inSingletonScope();
container
  .bind("RobotGateway")
  .to(robotsGateways.RobotServerGateway)
  .inSingletonScope();
container
  .bind("RobotSampleGateway")
  .to(robotsGateways.RobotSampleServerGateway)
  .inSingletonScope();
container
  .bind("RobotTestGateway")
  .to(robotsGateways.RobotTestServerGateway)
  .inSingletonScope();
container
  .bind("ReportPlusGateway")
  .to(reportsGateways.ReportPlusServerGateway)
  .inSingletonScope();
container
  .bind(reportsGateways.ReportPlusLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("ReportPlusTemplateGateway")
  .to(uploadsGateways.ReportPlusTemplateServerGateway)
  .inSingletonScope();
container
  .bind(uploadsGateways.ReportPlusTemplateLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("CustomReportGateway")
  .to(reportsGateways.CustomReportServerGateway)
  .inSingletonScope();
container
  .bind("CustomReportBehaviourGateway")
  .to(reportsGateways.CustomReportBehaviourServerGateway)
  .inSingletonScope();
container
  .bind(reportsGateways.CustomReportBehaviourLocalGateway)
  .toSelf()
  .inSingletonScope();
container
  .bind("CustomReportUserGateway")
  .to(reportsGateways.CustomReportUserServerGateway)
  .inSingletonScope();
container
  .bind(reportsGateways.CustomReportUserLocalGateway)
  .toSelf()
  .inSingletonScope();
//} GATEWAYS

//{ CONTROLLERS
container
  .bind(RouterController)
  .to(RouterController)
  .inSingletonScope();
container
  .bind(LoginScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(OverviewScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(BehaviourEditScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(BehaviourListScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenosetListScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenosetCreateScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenosetEditScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(SnpListScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(SnpEditScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(SnpCreateScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenotypeListScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenotypeCreateScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenotypeEditScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(BehaviourCreateScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportListScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportCreateScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportEditScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanListScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanCreateScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanEditScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(UserListScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(UserCreateScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(UserEditScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchListScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchCreateScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchEditScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchDetailsScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanCodeListScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanCodeCreateScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenomemosetCreateScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenomemosetListScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenomemosetEditScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationListScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationCreateScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationEditScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationViewScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(SnpFrequencyListScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(SlotListScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(SlotCreateScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(SlotEditScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotListScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotCreateScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotEditScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotViewScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportPlusCreateScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportPlusEditScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportPlusListScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportPlusViewScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(CustomReportCreateScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(CustomReportEditScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(CustomReportListScreenController)
  .toSelf()
  .inSingletonScope();
container
  .bind(CustomReportViewScreenController)
  .toSelf()
  .inSingletonScope();
//} CONTROLLERS

//{ INTERACTORS
container
  .bind(Login_SubmitLoginInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Login_InitLoginScreenInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(BehaviourList_InitBehaviourListInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(BehaviourList_ChangeFilterInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(BehaviourEdit_InitBehaviourEditScreenInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(BehaviourList_SelectBehaviourEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(BehaviourEdit_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenosetList_InitGenosetListInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenosetList_ChangeFilterInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenosetCreate_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenosetCreate_InitGenosetCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenosetList_SelectCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenosetEdit_InitGenosetEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenosetList_SelectGenosetEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenosetEdit_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SnpList_InitSnpListInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SnpList_SelectCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SnpList_SelectSnpEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SnpEdit_InitSnpEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SnpCreate_InitSnpCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SnpEdit_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SnpCreate_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SnpList_ChangeFilterInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenotypeList_ChangeFilterInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenotypeList_InitGenotypeListInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenotypeList_SelectCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenotypeList_SelectGenotypeEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenotypeCreate_InitGenotypeCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenotypeCreate_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenotypeEdit_InitGenotypeEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenotypeEdit_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(BehaviourCreate_InitBehaviourCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(BehaviourCreate_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(BehaviourList_SelectCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportList_ChangeFilterInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportList_InitReportListInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportList_SelectReportEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportList_SelectCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportEdit_InitReportEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportEdit_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportCreate_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportCreate_InitReportCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanList_ChangeFilterInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanList_InitPlanListInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanList_SelectCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanList_SelectPlanEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanEdit_InitPlanEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanEdit_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanCreate_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanCreate_InitPlanCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(UserList_ChangeFilterInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(UserList_InitUserListInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(UserList_SelectCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(UserList_SelectUserEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(UserCreate_InitUserCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(UserCreate_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(UserEdit_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(UserEdit_InitUserEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchList_InitResearchListInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchList_ChangeFilterInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchList_SelectCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchList_SelectResearchEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchList_SelectResearchDetailsInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchCreate_InitResearchCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchCreate_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchEdit_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchEdit_InitResearchEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchDetails_InitResearchDetailsInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchDetails_SelectCellInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanCodeList_InitPlanCodeListInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanCodeList_ChangeFilterInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanCodeList_SelectCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanCodeCreate_InitPlanCodeCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(PlanCodeCreate_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(Overview_InitOverviewInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenomemosetList_ChangeFilterInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenomemosetList_InitGenomemosetListInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenomemosetList_SelectCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenomemosetList_SelectGenomemosetEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenomemosetCreate_InitGenomemosetCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenomemosetCreate_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenomemosetEdit_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenomemosetEdit_InitGenomemosetEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchDetails_SelectResearchProcessInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ResearchDetails_SelectResearchEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationList_InitCorrelationListScreenInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationList_ChangeFilterInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationList_SelectCorrelationEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationList_SelectCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationCreate_InitCorrelationCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationCreate_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationEdit_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationEdit_InitCorrelationEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationList_SelectCorrelationViewInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationView_InitCorrelationViewInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationView_SubmitNewGroupInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationView_SubmitEditGroupInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationView_SubmitSwapGroupsInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationView_FindUserInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationView_SubmitNewGroupUserInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationView_SelectDeleteGroupUserInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationView_SelectGenerateModelInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationView_SubmitNewUserReportInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationView_SelectUserReportProcessInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationView_SelectCorrelationEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SnpFrequencyList_InitSnpFrequencyListScreenInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SnpFrequencyList_ChangeFilterInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SnpFrequencyList_SelectCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SnpFrequencyList_SelectSnpFrequencyEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SlotList_InitSlotListInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SlotList_ChangeFilterInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SlotList_SelectCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SlotList_SelectSlotEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SlotCreate_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SlotCreate_InitSlotCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SlotEdit_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(SlotEdit_InitSlotEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotCreate_InitRobotCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotCreate_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotEdit_InitRobotEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotEdit_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotList_ChangeFilterInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotList_InitRobotListInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotList_SelectRobotEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotList_SelectRobotViewInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotList_SelectCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotView_InitRobotViewInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotView_SubmitNewRobotSampleInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotView_SubmitNewRobotTestInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotView_SelectGenerateModelInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotView_SelectRobotEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotView_SelectRobotTestProcessInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotView_RefreshProgressInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotView_SelectRobotTestProcessAllInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CorrelationView_SelectUserReportProcessAllInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotView_SelectRobotSampleSpecificityInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(RobotList_SelectRobotDuplicateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportPlusCreate_InitReportPlusCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportPlusCreate_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportPlusEdit_InitReportPlusEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportPlusEdit_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportPlusList_ChangeFilterInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportPlusList_InitReportPlusListInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportPlusList_SelectReportPlusEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportPlusList_SelectReportPlusViewInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportPlusList_SelectCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(ReportPlusView_InitReportPlusViewInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(GenotypeList_SelectUpdateStatisticsInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CustomReportCreate_InitCustomReportCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CustomReportCreate_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CustomReportEdit_InitCustomReportEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CustomReportEdit_SubmitFormInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CustomReportList_ChangeFilterInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CustomReportList_InitCustomReportListInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CustomReportList_SelectCustomReportEditInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CustomReportList_SelectCustomReportViewInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CustomReportList_SelectCreateInteractor)
  .toSelf()
  .inSingletonScope();
container
  .bind(CustomReportView_InitCustomReportViewInteractor)
  .toSelf()
  .inSingletonScope();
//} INTERACTORS

container
  .bind("Localization")
  .to(Localization)
  .inSingletonScope();

export default container;
