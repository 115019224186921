<template>
  <div id="app" :class="vueAppClasses">
    <router-view @setAppClasses="setAppClasses" />
    <div class="app-version">
      <p>{{ version }}</p>
    </div>
  </div>
</template>

<script>
import themeConfig from "@/../themeConfig.js";
import { RouterController } from "@/adapters/controllers/router";
import { version } from "@/../package.json";
export default {
  name: "app",
  data() {
    return {
      version,
      vueAppClasses: [],
      routerController: null
    };
  },
  beforeMount() {
    this.routerController = this.$injector.get(RouterController);

    //{ ROUTER
    const r = this.routerController;
    r.pop = () => this.$router.pop();
    r.push = (name, params, query) =>
      this.$router.push({ name, params, query });
    r.open = (name, params, query) =>
      this.$router.replace({ name, params, query });
    r.pushUrl = route => this.$router.push(route);
    r.openUrl = route => this.$router.replace(route);
    r.pushLogin = () => r.push("Login", {}, { next: this.$route.fullPath });
    r.openLogin = () => r.open("Login", {}, { next: this.$route.fullPath });
    r.pushOverview = () => r.push("Overview");
    r.openOverview = () => r.open("Overview");
    r.pushBehaviourEdit = id => r.push("BehaviourEdit", { id });
    r.openBehaviourEdit = id => r.open("BehaviourEdit", { id });
    r.pushBehaviourList = () => r.push("BehaviourList");
    r.openBehaviourList = () => r.open("BehaviourList");
    r.pushGenosetList = () => r.push("GenosetList");
    r.openGenosetList = () => r.open("GenosetList");
    r.pushGenosetCreate = () => r.push("GenosetCreate");
    r.openGenosetCreate = () => r.open("GenosetCreate");
    r.pushGenosetEdit = id => r.push("GenosetEdit", { id });
    r.openGenosetEdit = id => r.open("GenosetEdit", { id });
    r.pushSnpList = () => r.push("SnpList");
    r.openSnpList = () => r.open("SnpList");
    r.pushSnpEdit = id => r.push("SnpEdit", { id });
    r.openSnpEdit = id => r.open("SnpEdit", { id });
    r.pushSnpCreate = () => r.push("SnpCreate");
    r.openSnpCreate = () => r.open("SnpCreate");
    r.pushGenotypeList = () => r.push("GenotypeList");
    r.openGenotypeList = () => r.open("GenotypeList");
    r.pushGenotypeCreate = () => r.push("GenotypeCreate");
    r.openGenotypeCreate = () => r.open("GenotypeCreate");
    r.pushGenotypeEdit = id => r.push("GenotypeEdit", { id });
    r.openGenotypeEdit = id => r.open("GenotypeEdit", { id });
    r.pushBehaviourCreate = () => r.push("BehaviourCreate");
    r.openBehaviourCreate = () => r.open("BehaviourCreate");
    r.pushReportList = () => r.push("ReportList");
    r.openReportList = () => r.open("ReportList");
    r.pushReportCreate = () => r.push("ReportCreate");
    r.openReportCreate = () => r.open("ReportCreate");
    r.pushReportEdit = id => r.push("ReportEdit", { id });
    r.openReportEdit = id => r.open("ReportEdit", { id });
    r.pushPlanList = () => r.push("PlanList");
    r.openPlanList = () => r.open("PlanList");
    r.pushPlanCreate = () => r.push("PlanCreate");
    r.openPlanCreate = () => r.open("PlanCreate");
    r.pushPlanEdit = id => r.push("PlanEdit", { id });
    r.openPlanEdit = id => r.open("PlanEdit", { id });
    r.pushUserList = () => r.push("UserList");
    r.openUserList = () => r.open("UserList");
    r.pushUserCreate = wue => r.push("UserCreate", {}, { wue });
    r.openUserCreate = wue => r.open("UserCreate", {}, { wue });
    r.pushUserEdit = (id, wue) => r.push("UserEdit", { id }, { wue });
    r.openUserEdit = (id, wue) => r.open("UserEdit", { id }, { wue });
    r.pushResearchList = () => r.push("ResearchList");
    r.openResearchList = () => r.open("ResearchList");
    r.pushResearchCreate = () => r.push("ResearchCreate");
    r.openResearchCreate = () => r.open("ResearchCreate");
    r.pushResearchEdit = id => r.push("ResearchEdit", { id });
    r.openResearchEdit = id => r.open("ResearchEdit", { id });
    r.pushResearchDetails = id => r.push("ResearchDetails", { id });
    r.openResearchDetails = id => r.open("ResearchDetails", { id });
    r.pushPlanCodeList = () => r.push("PlanCodeList");
    r.openPlanCodeList = () => r.open("PlanCodeList");
    r.pushPlanCodeCreate = () => r.push("PlanCodeCreate");
    r.openPlanCodeCreate = () => r.open("PlanCodeCreate");
    r.pushGenomemosetCreate = () => r.push("GenomemosetCreate");
    r.openGenomemosetCreate = () => r.open("GenomemosetCreate");
    r.pushGenomemosetList = () => r.push("GenomemosetList");
    r.openGenomemosetList = () => r.open("GenomemosetList");
    r.pushGenomemosetEdit = id => r.push("GenomemosetEdit", { id });
    r.openGenomemosetEdit = id => r.open("GenomemosetEdit", { id });
    r.pushCorrelationList = () => r.push("CorrelationList");
    r.openCorrelationList = () => r.open("CorrelationList");
    r.pushCorrelationCreate = () => r.push("CorrelationCreate");
    r.openCorrelationCreate = () => r.open("CorrelationCreate");
    r.pushCorrelationEdit = id => r.push("CorrelationEdit", { id });
    r.openCorrelationEdit = id => r.open("CorrelationEdit", { id });
    r.pushCorrelationView = id => r.push("CorrelationView", { id });
    r.openCorrelationView = id => r.open("CorrelationView", { id });
    r.pushSnpFrequencyList = () => r.push("SnpFrequencyList");
    r.openSnpFrequencyList = () => r.open("SnpFrequencyList");
    r.pushSlotList = () => r.push("SlotList");
    r.openSlotList = () => r.open("SlotList");
    r.pushSlotCreate = () => r.push("SlotCreate");
    r.openSlotCreate = () => r.open("SlotCreate");
    r.pushSlotEdit = id => r.push("SlotEdit", { id });
    r.openSlotEdit = id => r.open("SlotEdit", { id });
    r.pushRobotList = () => r.push("RobotList");
    r.openRobotList = () => r.open("RobotList");
    r.pushRobotCreate = () => r.push("RobotCreate");
    r.openRobotCreate = () => r.open("RobotCreate");
    r.pushRobotEdit = id => r.push("RobotEdit", { id });
    r.openRobotEdit = id => r.open("RobotEdit", { id });
    r.pushRobotView = id => r.push("RobotView", { id });
    r.openRobotView = id => r.open("RobotView", { id });
    r.pushReportPlusCreate = () => r.push("ReportPlusCreate");
    r.openReportPlusCreate = () => r.open("ReportPlusCreate");
    r.pushReportPlusEdit = () => r.push("ReportPlusEdit");
    r.openReportPlusEdit = () => r.open("ReportPlusEdit");
    r.pushReportPlusList = () => r.push("ReportPlusList");
    r.openReportPlusList = () => r.open("ReportPlusList");
    r.pushReportPlusView = () => r.push("ReportPlusView");
    r.openReportPlusView = () => r.open("ReportPlusView");
    r.pushCustomReportCreate = () => r.push("CustomReportCreate");
    r.openCustomReportCreate = () => r.open("CustomReportCreate");
    r.pushCustomReportEdit = id => r.push("CustomReportEdit", { id });
    r.openCustomReportEdit = id => r.open("CustomReportEdit", { id });
    r.pushCustomReportList = () => r.push("CustomReportList");
    r.openCustomReportList = () => r.open("CustomReportList");
    r.pushCustomReportView = id => r.push("CustomReportView", { id });
    r.openCustomReportView = id => r.open("CustomReportView", { id });
    //} ROUTER
  },
  watch: {
    "$store.state.theme"(val) {
      this.toggleClassInBody(val);
    },
    "$vs.rtl"(val) {
      document.documentElement.setAttribute("dir", val ? "rtl" : "ltr");
    }
  },
  methods: {
    toggleClassInBody(className) {
      if (className == "dark") {
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
        document.body.classList.add("theme-dark");
      } else if (className == "semi-dark") {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        document.body.classList.add("theme-semi-dark");
      } else {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr);
    },
    handleWindowResize() {
      this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);

      // Set --vh property
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    },
    handleScroll() {
      this.$store.commit("UPDATE_WINDOW_SCROLL_Y", window.scrollY);
    }
  },
  mounted() {
    this.toggleClassInBody(themeConfig.theme);
    this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  },
  async created() {
    let dir = this.$vs.rtl ? "rtl" : "ltr";
    document.documentElement.setAttribute("dir", dir);

    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style lang="scss">
.page {
  position: fixed;
  width: inherit;
}
.page-card {
  padding: 25px !important;
}
.centerx {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-wrap: wrap;
}

.centerx li {
  margin: 5px;
}

.app-version {
  position: absolute;
  left: 100vw;
  top: 100vh;
  transform: translate(-100%, -100%);
  font-size: 9px;
}
</style>
