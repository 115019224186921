import { injectable } from "inversify";
import ResearchCreateScreenData from "@/adapters/models/screen-research-create";

type EmptyAction = () => void;
type SetData = (data: ResearchCreateScreenData) => void;
type GetData = () => ResearchCreateScreenData;

@injectable()
export default class ResearchCreateScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
