import { injectable, inject } from "inversify";
import RobotViewScreenController from "@/adapters/controllers/screen-robot-view";
import { RouterController } from "@/adapters/controllers/router";
import { IRobotGateway } from "@/adapters/gateways/robots";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RobotTest } from "@/domain/robots/models";

@injectable()
export default class SelectRobotTestProcessAllInteractor {
  _robotViewScreenController: RobotViewScreenController;
  _robotGateway: IRobotGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(RobotViewScreenController)
    robotViewScreenController: RobotViewScreenController,
    @inject("RobotGateway")
    robotGateway: IRobotGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._robotViewScreenController = robotViewScreenController;
    this._robotGateway = robotGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(): Promise<void> {
    this._robotViewScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    const data = this._robotViewScreenController.getData!();
    const tests = data.tests;
    const tasks = [];
    for (let test of tests) {
      let task = this._robotGateway.testProcess(session.tokens!, test);
      tasks.push(task);
    }
    for (let task of tasks) {
      await task;
    }
    this._robotViewScreenController.setAsLoaded!();
    this._robotViewScreenController.reload!();
  }
}
