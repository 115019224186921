import { injectable, inject } from "inversify";
import PlanCodeListScreenController from "@/adapters/controllers/screen-plan-code-list";
import { IPlanCodeGateway } from "@/adapters/gateways/plans";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class ChangeFilterInteractor {
  _planCodeListScreenController: PlanCodeListScreenController;
  _planCodeGateway: IPlanCodeGateway;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(PlanCodeListScreenController)
    planCodeListScreenController: PlanCodeListScreenController,
    @inject("PlanCodeGateway")
    planCodeGateway: IPlanCodeGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._planCodeListScreenController = planCodeListScreenController;
    this._planCodeGateway = planCodeGateway;
    this._sessionGateway = sessionGateway;
  }

  async handle(page: number, search: string, sort: string): Promise<void> {
    this._planCodeListScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    const plansCodesPage = await this._planCodeGateway.list(
      session.tokens!,
      page,
      search,
      sort
    );
    this._planCodeListScreenController.setData!({ plansCodesPage });
    this._planCodeListScreenController.setAsLoaded!();
  }
}
