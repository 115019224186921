import { injectable, inject } from "inversify";
import RobotViewScreenController from "@/adapters/controllers/screen-robot-view";
import {
  IRobotGateway,
  IRobotSampleGateway,
  IRobotTestGateway
} from "@/adapters/gateways/robots";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class InitRobotViewInteractor {
  _robotViewScreenController: RobotViewScreenController;
  _robotGateway: IRobotGateway;
  _robotSampleGateway: IRobotSampleGateway;
  _robotTestGateway: IRobotTestGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(RobotViewScreenController)
    robotViewScreenController: RobotViewScreenController,
    @inject("RobotGateway")
    robotGateway: IRobotGateway,
    @inject("RobotSampleGateway")
    robotSampleGateway: IRobotSampleGateway,
    @inject("RobotTestGateway")
    robotTestGateway: IRobotTestGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._robotViewScreenController = robotViewScreenController;
    this._robotGateway = robotGateway;
    this._robotSampleGateway = robotSampleGateway;
    this._robotTestGateway = robotTestGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(id: string): Promise<void> {
    this._robotViewScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    const robot = await this._robotGateway.retrieve(session.tokens!, id);
    const samplesRequest = this._robotSampleGateway.list(
      session.tokens!,
      robot
    );
    const testsRequest = this._robotTestGateway.list(session.tokens!, robot);

    const samples = await samplesRequest;
    const tests = await testsRequest;

    this._robotViewScreenController.setData!({
      robot,
      samples,
      tests
    });

    this._robotViewScreenController.setAsLoaded!();
  }
}
