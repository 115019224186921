import { Entity } from "@/commons/models";
import { Slot } from "@/domain/slots/models";
const jsSHA = require("jssha");

export class User extends Entity {
  name: string;
  email: string;
  language: string;
  credentials: any[] = [];

  constructor(
    name: string,
    email: string,
    language: string,
    id?: string,
    createdAt?: Date,
    modifiedAt?: Date,
    disabledAt?: Date
  ) {
    super(id, createdAt, modifiedAt, disabledAt);
    this.name = name;
    this.email = email;
    this.language = language;
  }
}

export interface IUserPage {
  count: number;
  results: User[];
}

export class Credential extends Entity {
  user: User;
  type: number;
  isStaff: boolean;
  isActive: boolean;

  constructor(
    user: User,
    type: number,
    isStaff: boolean,
    isActive: boolean,
    id?: string,
    createdAt?: Date,
    modifiedAt?: Date,
    disabledAt?: Date
  ) {
    super(id, createdAt, modifiedAt, disabledAt);
    this.user = user;
    this.type = type;
    this.isStaff = isStaff;
    this.isActive = isActive;
  }

  get isDefaultUser(): boolean {
    return this.type == 1;
  }

  get isDevice(): boolean {
    return this.type == 2;
  }

  get isSystem(): boolean {
    return this.type == 3;
  }

  get isAdmin(): boolean {
    return this.type == 4;
  }
}

// const EMAIL_RE = /^(.+)@(.+){2,}\.(.+){2,}$/;

export function protectPassword(username: string, password: string): string {
  let sha = new jsSHA("SHA-512", "TEXT");
  sha.update(`${username}/${password}`);
  return sha.getHash("HEX");
}

export class UserSignIn {
  username: string;
  password: string;
  isProtected: boolean;

  constructor(
    username: string,
    password: string,
    isProtected: boolean = false
  ) {
    this.username = username;
    this.password = password;
    this.isProtected = isProtected;
  }

  isUsernameValid(): boolean {
    return this.username.length > 0;
  }

  isPasswordValid(): boolean {
    return this.password.length > 0;
  }

  protect(): boolean {
    if (!this.isProtected && this.isUsernameValid() && this.isPasswordValid()) {
      this.password = protectPassword(this.username, this.password);
      this.isProtected = true;
      return true;
    } else {
      return false;
    }
  }
}

export class Tokens {
  access: string;
  refresh: string;

  constructor(access: string, refresh: string) {
    this.access = access;
    this.refresh = refresh;
  }

  getHeader() {
    return {
      Authorization: `Bearer ${this.access}`
    };
  }
}

export interface Session {
  tokens?: Tokens;
  credential?: Credential;
  slot?: Slot;
}

export class UserNode extends Entity {
  constructor(
    id?: string,
    createdAt?: Date,
    modifiedAt?: Date,
    disabledAt?: Date
  ) {
    super(id, createdAt, modifiedAt, disabledAt);
  }
}
