import { injectable, inject } from "inversify";
import PlanCodeCreateScreenController from "@/adapters/controllers/screen-plan-code-create";
import { IPlanGateway } from "@/adapters/gateways/plans";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class InitPlanCodeCreateInteractor {
  _planCodeCreateScreenController: PlanCodeCreateScreenController;
  _planGateway: IPlanGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(PlanCodeCreateScreenController)
    planCodeCreateScreenController: PlanCodeCreateScreenController,
    @inject("PlanGateway")
    planGateway: IPlanGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._planCodeCreateScreenController = planCodeCreateScreenController;
    this._planGateway = planGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._planCodeCreateScreenController.setAsLoading!();
    const plans = (await this._planGateway.list(session.tokens)).results;
    this._planCodeCreateScreenController.setData!({ plans });
    this._planCodeCreateScreenController.setAsLoaded!();
  }
}
