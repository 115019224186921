import { injectable, inject } from "inversify";
import CustomReportCreateScreenController from "@/adapters/controllers/screen-custom-report-create";
import { ICustomReportGateway } from "@/adapters/gateways/reports";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { CustomReport } from "@/domain/reports/models";

@injectable()
export default class SubmitFormInteractor {
  _customReportCreateScreenController: CustomReportCreateScreenController;
  _customReportGateway: ICustomReportGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(CustomReportCreateScreenController)
    customReportCreateScreenController: CustomReportCreateScreenController,
    @inject("CustomReportGateway")
    customReportGateway: ICustomReportGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._customReportCreateScreenController = customReportCreateScreenController;
    this._customReportGateway = customReportGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(customReport: CustomReport): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._customReportCreateScreenController.setAsLoading!();
    await this._customReportGateway.create(customReport, session.tokens!);
    this._customReportCreateScreenController.setAsLoaded!();
    this._routerController.pushCustomReportList!();
  }
}
