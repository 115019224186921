import { injectable, inject } from "inversify";
import ReportListScreenController from "@/adapters/controllers/screen-report-list";
import { RouterController } from "@/adapters/controllers/router";
import { Report } from "@/domain/reports/models";

@injectable()
export default class SelectReportEditInteractor {
  _reportListScreenController: ReportListScreenController;
  _routerController: RouterController;

  constructor(
    @inject(ReportListScreenController)
    reportListScreenController: ReportListScreenController,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._reportListScreenController = reportListScreenController;
    this._routerController = routerController;
  }

  async handle(report: Report): Promise<void> {
    this._routerController.pushReportEdit!(report.id);
  }
}
