import ResearchListScreenController from "@/adapters/controllers/screen-research-list";
import { IResearchGateway } from "@/adapters/gateways/researches";
import { ISessionGateway } from "@/adapters/gateways/users";
import { inject, injectable } from "inversify";

@injectable()
export default class ChangeFilterInteractor {
  _researchListScreenController: ResearchListScreenController;
  _researchGateway: IResearchGateway;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(ResearchListScreenController)
    researchListScreenController: ResearchListScreenController,
    @inject("ResearchGateway")
    researchGateway: IResearchGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._researchListScreenController = researchListScreenController;
    this._researchGateway = researchGateway;
    this._sessionGateway = sessionGateway;
  }

  async handle(page: number, search: string, sort: string): Promise<void> {
    this._researchListScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    const researchesPage = await this._researchGateway.list(
      session.tokens!,
      page,
      search,
      sort
    );
    this._researchListScreenController.setData!({ researchesPage });
    this._researchListScreenController.setAsLoaded!();
  }
}
