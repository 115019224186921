import { injectable, inject } from "inversify";
import ReportPlusEditScreenController from "@/adapters/controllers/screen-report-plus-edit";

@injectable()
export default class SubmitFormInteractor {
  _reportPlusEditScreenController: ReportPlusEditScreenController;

  constructor(
    @inject(ReportPlusEditScreenController)
    reportPlusEditScreenController: ReportPlusEditScreenController
  ) {
    this._reportPlusEditScreenController = reportPlusEditScreenController;
  }

  async handle(): Promise<void> {}
}
