import { injectable, inject } from "inversify";
import RobotListScreenController from "@/adapters/controllers/screen-robot-list";
import { IRobotGateway } from "@/adapters/gateways/robots";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class ChangeFilterInteractor {
  _robotListScreenController: RobotListScreenController;
  _sessionGateway: ISessionGateway;
  _robotGateway: IRobotGateway;

  constructor(
    @inject(RobotListScreenController)
    robotListScreenController: RobotListScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject("RobotGateway")
    robotGateway: IRobotGateway
  ) {
    this._robotListScreenController = robotListScreenController;
    this._robotGateway = robotGateway;
    this._sessionGateway = sessionGateway;
  }

  async handle(page: number, search: string, sort: string): Promise<void> {
    this._robotListScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    const robotsPage = await this._robotGateway.list(
      session.tokens!,
      page,
      search,
      sort
    );
    this._robotListScreenController.setData!({ robotsPage });
    this._robotListScreenController.setAsLoaded!();
  }
}
