import { injectable, inject } from "inversify";
import SnpListScreenController from "@/adapters/controllers/screen-snp-list";
import { ISnpGateway } from "@/adapters/gateways/dna";
import { RouterController } from "@/adapters/controllers/router";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class InitSnpListInteractor {
  _snpListScreenController: SnpListScreenController;
  _snpGateway: ISnpGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(SnpListScreenController)
    snpListScreenController: SnpListScreenController,
    @inject("SnpGateway")
    snpGateway: ISnpGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._snpListScreenController = snpListScreenController;
    this._snpGateway = snpGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._snpListScreenController.setAsLoading!();
    const snpsPage = await this._snpGateway.list(session.tokens!);
    this._snpListScreenController.setData!({ snpsPage });
    this._snpListScreenController.setAsLoaded!();
  }
}
