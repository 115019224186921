import { RouterController } from "@/adapters/controllers/router";
import CustomReportEditScreenController from "@/adapters/controllers/screen-custom-report-edit";
import { ICustomReportGateway } from "@/adapters/gateways/reports";
import { ISessionGateway } from "@/adapters/gateways/users";
import { CustomReport } from "@/domain/reports/models";
import { inject, injectable } from "inversify";

@injectable()
export default class SubmitFormInteractor {
  _customReportEditScreenController: CustomReportEditScreenController;
  _customReportGateway: ICustomReportGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(CustomReportEditScreenController)
    customReportEditScreenController: CustomReportEditScreenController,
    @inject("CustomReportGateway")
    customReportGateway: ICustomReportGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._customReportEditScreenController = customReportEditScreenController;
    this._customReportGateway = customReportGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(customReport: CustomReport): Promise<void> {
    this._customReportEditScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    await this._customReportGateway.update(customReport, session.tokens!);
    this._routerController.pushCustomReportList!();
    this._customReportEditScreenController.setAsLoaded!();
  }
}
