import { injectable, inject } from "inversify";
import ReportListScreenController from "@/adapters/controllers/screen-report-list";
import { ISessionGateway } from "@/adapters/gateways/users";
import { IReportGateway } from "@/adapters/gateways/reports";

@injectable()
export default class ChangeFilterInteractor {
  _reportListScreenController: ReportListScreenController;
  _sessionGateway: ISessionGateway;
  _reportGateway: IReportGateway;

  constructor(
    @inject(ReportListScreenController)
    reportListScreenController: ReportListScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject("ReportGateway")
    reportGateway: IReportGateway
  ) {
    this._reportListScreenController = reportListScreenController;
    this._reportGateway = reportGateway;
    this._sessionGateway = sessionGateway;
  }

  async handle(page: number, search: string, sort: string): Promise<void> {
    this._reportListScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    const reportsPage = await this._reportGateway.list(
      session.tokens!,
      page,
      search,
      sort
    );
    this._reportListScreenController.setData!({ reportsPage });
    this._reportListScreenController.setAsLoaded!();
  }
}
