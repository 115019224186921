import { injectable } from "inversify";
import GenosetListScreenData from "@/adapters/models/screen-genoset-list";

type EmptyAction = () => void;
type SetData = (data: GenosetListScreenData) => void;
type GetData = () => GenosetListScreenData;

@injectable()
export default class GenosetListScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
