import { injectable, inject } from "inversify";
import ResearchCreateScreenController from "@/adapters/controllers/screen-research-create";
import { IResearchGateway } from "@/adapters/gateways/researches";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { Research } from "@/domain/researches/models";

@injectable()
export default class SubmitFormInteractor {
  _researchCreateScreenController: ResearchCreateScreenController;
  _researchGateway: IResearchGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(ResearchCreateScreenController)
    researchCreateScreenController: ResearchCreateScreenController,
    @inject("ResearchGateway")
    researchGateway: IResearchGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._researchCreateScreenController = researchCreateScreenController;
    this._researchGateway = researchGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(research: Research): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._researchCreateScreenController.setAsLoading!();
    await this._researchGateway.create(research, session.tokens!);
    this._researchCreateScreenController.setAsLoaded!();
    this._routerController.pushResearchList!();
  }
}
