import { injectable, inject } from "inversify";
import GenotypeListScreenController from "@/adapters/controllers/screen-genotype-list";
import { IGenotypeGateway } from "@/adapters/gateways/dna";
import { RouterController } from "@/adapters/controllers/router";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class InitGenotypeListInteractor {
  _genotypeListScreenController: GenotypeListScreenController;
  _genotypeGateway: IGenotypeGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(GenotypeListScreenController)
    genotypeListScreenController: GenotypeListScreenController,
    @inject("GenotypeGateway")
    genotypeGateway: IGenotypeGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._genotypeListScreenController = genotypeListScreenController;
    this._genotypeGateway = genotypeGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._genotypeListScreenController.setAsLoading!();
    const genotypesPage = await this._genotypeGateway.list(session.tokens!);
    this._genotypeListScreenController.setData!({ genotypesPage });
    this._genotypeListScreenController.setAsLoaded!();
  }
}
