import { injectable } from "inversify";
import GenomemosetEditScreenData from "@/adapters/models/screen-genomemoset-edit";

type EmptyAction = () => void;
type SetData = (data: GenomemosetEditScreenData) => void;
type GetData = () => GenomemosetEditScreenData;

@injectable()
export default class GenomemosetEditScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
