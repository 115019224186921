import { RouterController } from "@/adapters/controllers/router";
import ResearchEditScreenController from "@/adapters/controllers/screen-research-edit";
import { IResearchGateway } from "@/adapters/gateways/researches";
import { ISessionGateway } from "@/adapters/gateways/users";
import { inject, injectable } from "inversify";

@injectable()
export default class InitResearchEditInteractor {
  _researchEditScreenController: ResearchEditScreenController;
  _researchGateway: IResearchGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(ResearchEditScreenController)
    researchEditScreenController: ResearchEditScreenController,
    @inject("ResearchGateway")
    researchGateway: IResearchGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._researchEditScreenController = researchEditScreenController;
    this._researchGateway = researchGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(id: string): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._researchEditScreenController.setAsLoading!();
    const research = await this._researchGateway.retrieve(id, session.tokens!);
    const diagnostics = await this._researchGateway.listDiagnostics(
      session.tokens!
    );
    this._researchEditScreenController.setData!({ research, diagnostics });
    this._researchEditScreenController.setAsLoaded!();
  }
}
