import { injectable, inject } from "inversify";
import CorrelationViewScreenController from "@/adapters/controllers/screen-correlation-view";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { ICorrelationUserReportGateway } from "@/adapters/gateways/correlations";
import { CorrelationUserReport } from "@/domain/correlations/models";

@injectable()
export default class SelectUserReportProcessAllInteractor {
  _correlationViewScreenController: CorrelationViewScreenController;
  _correlationUserReportGateway: ICorrelationUserReportGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(CorrelationViewScreenController)
    correlationViewScreenController: CorrelationViewScreenController,
    @inject("CorrelationUserReportGateway")
    correlationUserReportGateway: ICorrelationUserReportGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._correlationViewScreenController = correlationViewScreenController;
    this._correlationUserReportGateway = correlationUserReportGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._correlationViewScreenController.setAsLoading!();
    const data = this._correlationViewScreenController.getData!();
    const usersReports = data.usersReports;
    const tasks = [];
    for (let userReport of usersReports) {
      let task = this._correlationUserReportGateway.process(
        session.tokens,
        userReport.id
      );
      tasks.push(task);
    }
    for (let task of tasks) {
      await task;
    }
    this._correlationViewScreenController.setAsLoaded!();
    this._correlationViewScreenController.reload!();
  }
}
