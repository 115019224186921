import { injectable, inject } from "inversify";
import RobotListScreenController from "@/adapters/controllers/screen-robot-list";
import { Robot } from "@/domain/robots/models";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class SelectRobotEditInteractor {
  _robotListScreenController: RobotListScreenController;
  _routerController: RouterController;

  constructor(
    @inject(RobotListScreenController)
    robotListScreenController: RobotListScreenController,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._robotListScreenController = robotListScreenController;
    this._routerController = routerController;
  }

  async handle(robot: Robot): Promise<void> {
    this._routerController.pushRobotEdit!(robot.id);
  }
}
