import { RouterController } from "@/adapters/controllers/router";
import GenomemosetEditScreenController from "@/adapters/controllers/screen-genomemoset-edit";
import { IGenomemosetGateway } from "@/adapters/gateways/dna";
import { IResearchGateway } from "@/adapters/gateways/researches";
import { ISessionGateway } from "@/adapters/gateways/users";
import { inject, injectable } from "inversify";

@injectable()
export default class InitGenomemosetEditInteractor {
  _genomemosetEditScreenController: GenomemosetEditScreenController;
  _genomemosetGateway: IGenomemosetGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;
  _researchGateway: IResearchGateway;

  constructor(
    @inject(GenomemosetEditScreenController)
    genomemosetEditScreenController: GenomemosetEditScreenController,
    @inject("GenomemosetGateway")
    genomemosetGateway: IGenomemosetGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject("ResearchGateway")
    researchGateway: IResearchGateway
  ) {
    this._genomemosetEditScreenController = genomemosetEditScreenController;
    this._genomemosetGateway = genomemosetGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
    this._researchGateway = researchGateway;
  }

  async handle(id: string): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._genomemosetEditScreenController.setAsLoading!();
    const genomemoset = await this._genomemosetGateway.retrieve(
      id,
      session.tokens!
    );
    const diagnostics = await this._researchGateway.listDiagnostics(
      session.tokens!
    );
    this._genomemosetEditScreenController.setData!({
      genomemoset,
      diagnostics
    });
    this._genomemosetEditScreenController.setAsLoaded!();
  }
}
