import { injectable, inject } from "inversify";
import RobotViewScreenController from "@/adapters/controllers/screen-robot-view";
import { IRobotSampleGateway } from "@/adapters/gateways/robots";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { RobotSample } from "@/domain/robots/models";

@injectable()
export default class SubmitNewRobotSampleInteractor {
  _robotViewScreenController: RobotViewScreenController;
  _robotSampleGateway: IRobotSampleGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(RobotViewScreenController)
    robotViewScreenController: RobotViewScreenController,
    @inject("RobotSampleGateway")
    robotSampleGateway: IRobotSampleGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._robotViewScreenController = robotViewScreenController;
    this._robotSampleGateway = robotSampleGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(instance: RobotSample): Promise<void> {
    this._robotViewScreenController.setAsLoading!();

    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }

    await this._robotSampleGateway.create(session.tokens!, instance);

    this._robotViewScreenController.reload!();
    this._robotViewScreenController.setAsLoaded!();
  }
}
