import { RouterController } from "@/adapters/controllers/router";
import CustomReportEditScreenController from "@/adapters/controllers/screen-custom-report-edit";
import { ICustomReportGateway } from "@/adapters/gateways/reports";
import { ISessionGateway } from "@/adapters/gateways/users";
import { inject, injectable } from "inversify";

@injectable()
export default class InitCustomReportEditScreenInteractor {
  _customReportEditScreenController: CustomReportEditScreenController;
  _customReportGateway: ICustomReportGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(CustomReportEditScreenController)
    customReportEditScreenController: CustomReportEditScreenController,
    @inject("CustomReportGateway")
    customReportGateway: ICustomReportGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._customReportEditScreenController = customReportEditScreenController;
    this._customReportGateway = customReportGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(id: string): Promise<void> {
    this._customReportEditScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    const customReport = await this._customReportGateway.retrieve(
      id,
      session.tokens!
    );
    this._customReportEditScreenController.setData!({ customReport });
    this._customReportEditScreenController.setAsLoaded!();
  }
}
