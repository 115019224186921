import { RouterController } from "@/adapters/controllers/router";
import RobotViewScreenController from "@/adapters/controllers/screen-robot-view";
import { IRobotGateway } from "@/adapters/gateways/robots";
import { ISessionGateway } from "@/adapters/gateways/users";
import { Robot } from "@/domain/robots/models";
import { inject, injectable } from "inversify";

@injectable()
export default class SelectGenerateModelInteractor {
  _robotViewScreenController: RobotViewScreenController;
  _robotGateway: IRobotGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(RobotViewScreenController)
    robotViewScreenController: RobotViewScreenController,
    @inject("RobotGateway")
    robotGateway: IRobotGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._robotViewScreenController = robotViewScreenController;
    this._robotGateway = robotGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(instance: Robot): Promise<void> {
    this._robotViewScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    await this._robotGateway.generateModel(session.tokens!, instance);
    this._robotViewScreenController.setAsLoaded!();
    this._robotViewScreenController.reload!();
  }
}
