import { injectable, inject } from "inversify";
import PlanListScreenController from "@/adapters/controllers/screen-plan-list";
import { IPlanGateway } from "@/adapters/gateways/plans";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class ChangeFilterInteractor {
  _planListScreenController: PlanListScreenController;
  _planGateway: IPlanGateway;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(PlanListScreenController)
    planListScreenController: PlanListScreenController,
    @inject("PlanGateway")
    planGateway: IPlanGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._planListScreenController = planListScreenController;
    this._planGateway = planGateway;
    this._sessionGateway = sessionGateway;
  }

  async handle(page: number, search: string, sort: string): Promise<void> {
    this._planListScreenController.setAsLoading!();
    const session = this._sessionGateway.get();
    const plansPage = await this._planGateway.list(
      session.tokens!,
      page,
      search,
      sort
    );
    this._planListScreenController.setData!({ plansPage });
    this._planListScreenController.setAsLoaded!();
  }
}
