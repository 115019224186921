import { injectable } from "inversify";
import RobotViewScreenData from "@/adapters/models/screen-robot-view";

type EmptyAction = () => void;
type SetData = (data: RobotViewScreenData) => void;
type GetData = () => RobotViewScreenData;

@injectable()
export default class RobotViewScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  reload?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
