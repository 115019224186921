import { injectable, inject } from "inversify";
import CorrelationListScreenController from "@/adapters/controllers/screen-correlation-list";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { ICorrelationGateway } from "@/adapters/gateways/correlations";

@injectable()
export default class InitCorrelationListInteractor {
  _correlationListScreenController: CorrelationListScreenController;

  _sessionGateway: ISessionGateway;
  _routerController: RouterController;
  _correlationGateway: ICorrelationGateway;

  constructor(
    @inject(CorrelationListScreenController)
    correlationScreenController: CorrelationListScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject("CorrelationGateway")
    correlationGateway: ICorrelationGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._correlationListScreenController = correlationScreenController;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
    this._correlationGateway = correlationGateway;
  }

  async handle(): Promise<void> {
    this._correlationListScreenController.setAsLoading!();
    let session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }

    const correlationsPage = await this._correlationGateway.list(
      session.tokens!
    );
    this._correlationListScreenController.setData!({ correlationsPage });
    this._correlationListScreenController.setAsLoaded!();
  }
}
