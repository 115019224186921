import { injectable, inject } from "inversify";
import GenomemosetListScreenController from "@/adapters/controllers/screen-genomemoset-list";
import { IGenomemosetGateway } from "@/adapters/gateways/dna";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class InitGenomemosetListInteractor {
  _genomemosetListScreenController: GenomemosetListScreenController;
  _genomemosetGateway: IGenomemosetGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(GenomemosetListScreenController)
    genomemosetListScreenController: GenomemosetListScreenController,
    @inject("GenomemosetGateway")
    genomemosetGateway: IGenomemosetGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._genomemosetListScreenController = genomemosetListScreenController;
    this._genomemosetGateway = genomemosetGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._genomemosetListScreenController.setAsLoading!();
    const genomemosetsPage = await this._genomemosetGateway.list(
      session.tokens!
    );
    this._genomemosetListScreenController.setData!({ genomemosetsPage });
    this._genomemosetListScreenController.setAsLoaded!();
  }
}
