import { injectable, inject } from "inversify";
import CorrelationViewScreenController from "@/adapters/controllers/screen-correlation-view";
import { CorrelationGroup } from "@/domain/correlations/models";
import { ICorrelationGroupGateway } from "@/adapters/gateways/correlations";
import { RouterController } from "@/adapters/controllers/router";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class SubmitSwapGroupInteractor {
  _correlationViewScreenController: CorrelationViewScreenController;
  _correlationGroupGateway: ICorrelationGroupGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(CorrelationViewScreenController)
    correlationViewScreenController: CorrelationViewScreenController,
    @inject("CorrelationGroupGateway")
    correlationGroupGateway: ICorrelationGroupGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._correlationViewScreenController = correlationViewScreenController;
    this._correlationGroupGateway = correlationGroupGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(
    group1: CorrelationGroup,
    group2: CorrelationGroup
  ): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }

    this._correlationViewScreenController.setAsLoading!();
    await this._correlationGroupGateway.specificitySwap(
      session.tokens,
      group1,
      group2
    );
    this._correlationViewScreenController.setAsLoaded!();
    this._correlationViewScreenController.reload!();
  }
}
