import { RouterController } from "@/adapters/controllers/router";
import GenotypeEditScreenController from "@/adapters/controllers/screen-genotype-edit";
import { IGenotypeGateway } from "@/adapters/gateways/dna";
import { ISessionGateway } from "@/adapters/gateways/users";
import { Genotype } from "@/domain/dna/models";
import { inject, injectable } from "inversify";

@injectable()
export default class SubmitFormInteractor {
  _genotypeEditScreenController: GenotypeEditScreenController;
  _genotypeGateway: IGenotypeGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(GenotypeEditScreenController)
    genotypeEditScreenController: GenotypeEditScreenController,
    @inject("GenotypeGateway")
    genotypeGateway: IGenotypeGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._genotypeEditScreenController = genotypeEditScreenController;
    this._genotypeGateway = genotypeGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(genotype: Genotype): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._genotypeEditScreenController.setAsLoading!();
    await this._genotypeGateway.update(genotype, session.tokens!);
    this._genotypeEditScreenController.setAsLoaded!();
    this._routerController.pushGenotypeList!();
  }
}
