import { injectable, inject } from "inversify";
import RobotCreateScreenController from "@/adapters/controllers/screen-robot-create";
import { IRobotGateway } from "@/adapters/gateways/robots";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { Robot } from "@/domain/robots/models";

@injectable()
export default class SubmitFormInteractor {
  _robotCreateScreenController: RobotCreateScreenController;
  _robotGateway: IRobotGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(RobotCreateScreenController)
    robotCreateScreenController: RobotCreateScreenController,
    @inject("RobotGateway")
    robotGateway: IRobotGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._robotCreateScreenController = robotCreateScreenController;
    this._robotGateway = robotGateway;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
  }

  async handle(robot: Robot): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._robotCreateScreenController.setAsLoading!();
    await this._robotGateway.create(robot, session.tokens!);
    this._robotCreateScreenController.setAsLoaded!();
    this._routerController.pushRobotList!();
  }
}
