import { injectable } from "inversify";
import SlotEditScreenData from "@/adapters/models/screen-slot-edit";

type EmptyAction = () => void;
type SetData = (data: SlotEditScreenData) => void;
type GetData = () => SlotEditScreenData;

@injectable()
export default class SlotEditScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
