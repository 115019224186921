import { injectable, inject } from "inversify";
import LoginScreenController from "@/adapters/controllers/screen-login";
import { UserSignIn } from "@/domain/users/models";
import { IUserGateway, ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { Localization } from "@/commons/localization";
import { ISlotGateway } from "@/adapters/gateways/slots";

@injectable()
export default class SubmitLoginInteractor {
  _loginScreenController: LoginScreenController;
  _userGateway: IUserGateway;
  _sessionGateway: ISessionGateway;
  _slotGateway: ISlotGateway;
  _routerController: RouterController;
  _localization: Localization;

  constructor(
    @inject(LoginScreenController)
    loginScreenController: LoginScreenController,
    @inject("UserGateway")
    userGateway: IUserGateway,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject("SlotGateway")
    slotGateway: ISlotGateway,
    @inject(RouterController)
    routerController: RouterController,

    @inject("Localization")
    localization: Localization
  ) {
    this._loginScreenController = loginScreenController;
    this._userGateway = userGateway;
    this._sessionGateway = sessionGateway;
    this._slotGateway = slotGateway;
    this._routerController = routerController;
    this._localization = localization;
  }

  async handle(userSignIn: UserSignIn, next?: string): Promise<void> {
    this._loginScreenController.clearFormErrors!();
    let isValid = true;

    if (!userSignIn.isUsernameValid()) {
      this._loginScreenController.setAsUsernameInvalid!();
      isValid = false;
    }

    if (!userSignIn.isPasswordValid()) {
      this._loginScreenController.setAsPasswordInvalid!();
      isValid = false;
    }

    if (!isValid) return;

    this._loginScreenController.setAsLoading!();
    try {
      userSignIn.protect();
      let tokens = await this._userGateway.signIn(userSignIn);
      let credential = await this._userGateway.me(tokens);
      if (!credential.isAdmin) {
        this._loginScreenController.setAsPermissionInvalid!();
        this._loginScreenController.setAsLoaded!();
        return;
      }
      let slot = await this._slotGateway.my(tokens);
      this._sessionGateway.set({ tokens, credential, slot });
      this._localization.set(credential.user.language);
      if (next == null) {
        this._routerController.pushOverview!();
      } else {
        this._routerController.pushUrl!(next);
      }
    } catch (error) {
      if (error && error.response && error.response.status == 401) {
        this._loginScreenController.setAsUsernameAndPasswordInvalid!();
      }
      this._loginScreenController.setAsLoaded!();
    }
  }
}
