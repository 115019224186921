import { RouterController } from "@/adapters/controllers/router";
import { inject, injectable } from "inversify";

@injectable()
export default class SelectCreateInteractor {
  _routerController: RouterController;

  constructor(
    @inject(RouterController)
    routerController: RouterController
  ) {
    this._routerController = routerController;
  }

  async handle(): Promise<void> {
    this._routerController.pushReportCreate!();
  }
}
