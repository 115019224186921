import { injectable, inject } from "inversify";
import GenotypeEditScreenController from "@/adapters/controllers/screen-genotype-edit";
import { IGenotypeGateway } from "@/adapters/gateways/dna";
import { RouterController } from "@/adapters/controllers/router";
import { ISessionGateway } from "@/adapters/gateways/users";

@injectable()
export default class InitGenotypeEditInteractor {
  _genotypeEditScreenController: GenotypeEditScreenController;
  _genotypeGateway: IGenotypeGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(GenotypeEditScreenController)
    genotypeEditScreenController: GenotypeEditScreenController,
    @inject("GenotypeGateway")
    genotypeGateway: IGenotypeGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._genotypeEditScreenController = genotypeEditScreenController;
    this._genotypeGateway = genotypeGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(id: string): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._genotypeEditScreenController.setAsLoading!();
    const genotype = await this._genotypeGateway.retrieve(id, session.tokens!);
    this._genotypeEditScreenController.setData!({ genotype });
    this._genotypeEditScreenController.setAsLoaded!();
  }
}
