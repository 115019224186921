import { injectable } from "inversify";
import RobotCreateScreenData from "@/adapters/models/screen-robot-create";

type EmptyAction = () => void;
type SetData = (data: RobotCreateScreenData) => void;
type GetData = () => RobotCreateScreenData;

@injectable()
export default class RobotCreateScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
