import { RouterController } from "@/adapters/controllers/router";
import ResearchDetailsScreenController from "@/adapters/controllers/screen-research-details";
import { IResearchGateway } from "@/adapters/gateways/researches";
import { ISessionGateway } from "@/adapters/gateways/users";
import { inject, injectable } from "inversify";

@injectable()
export default class InitResearchDetailsInteractor {
  _researchDetailsScreenController: ResearchDetailsScreenController;
  _researchGateway: IResearchGateway;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;

  constructor(
    @inject(ResearchDetailsScreenController)
    researchDetailsScreenController: ResearchDetailsScreenController,
    @inject("ResearchGateway")
    researchGateway: IResearchGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._researchDetailsScreenController = researchDetailsScreenController;
    this._researchGateway = researchGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(id: string): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._researchDetailsScreenController.setAsLoading!();
    const research = await this._researchGateway.retrieve(id, session.tokens!);
    const results = await this._researchGateway.results(id, session.tokens);
    const cellsIds: string[][] = [];
    const cellsLabels: string[][] = [];
    const cellsMultichoicesLabels: string[][] = [];

    let currentRowCellsIds: string[] = [];
    let currentRowCellsLabels: string[] = [];
    let currentRowCellsMultichoicesLabels: string[] = [];
    let currentRowId: string = results.cells[0].row;
    let currentRow: number[][] = [];
    let currentCounts: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    results.results = [];
    for (const cell of results.cells) {
      if (cell.row != currentRowId) {
        currentRow.push(currentCounts); // samples count
        currentRow.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); // margin of error
        results.results.push(currentRow);
        currentRowId = cell.row;
        currentRow = [];
        currentCounts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        cellsIds.push(currentRowCellsIds);
        currentRowCellsIds = [];
        cellsLabels.push(currentRowCellsLabels);
        currentRowCellsLabels = [];
        cellsMultichoicesLabels.push(currentRowCellsMultichoicesLabels);
        currentRowCellsMultichoicesLabels = [];
      }
      currentRowCellsIds.push(cell.cell);
      currentRowCellsLabels.push(cell.label);
      currentRowCellsMultichoicesLabels.push(cell.multichoicesLabel);
      currentCounts[0] += cell.p1Positive + cell.p1Negative;
      currentCounts[1] += cell.p1Positive;
      currentCounts[2] += cell.p1Negative;
      currentCounts[3] += cell.p1Dual;
      currentCounts[4] += cell.sam;
      currentCounts[5] += cell.p2Positive + cell.p2Negative;
      currentCounts[6] += cell.p2Positive;
      currentCounts[7] += cell.p2Negative;
      currentCounts[8] += cell.p2Dual;
      currentCounts[9] += cell.p3Positive + cell.p3Negative;
      currentCounts[10] += cell.p3Positive;
      currentCounts[11] += cell.p3Negative;
      currentCounts[12] += cell.p3Dual;
      currentRow.push([
        cell.p1Positive + cell.p1Negative || 0,
        cell.p1Positive || 0,
        cell.p1Negative || 0,
        cell.p1Dual || 0,
        cell.sam || 0,
        cell.p2Positive + cell.p2Negative || 0,
        cell.p2Positive || 0,
        cell.p2Negative || 0,
        cell.p2Dual || 0,
        cell.p3Positive + cell.p3Negative || 0,
        cell.p3Positive || 0,
        cell.p3Negative || 0,
        cell.p3Dual || 0
      ]);
    }
    currentRow.push(currentCounts); // samples count
    currentRow.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); // margin of error
    results.results.push(currentRow);
    cellsIds.push(currentRowCellsIds);
    cellsLabels.push(currentRowCellsLabels);
    cellsMultichoicesLabels.push(currentRowCellsMultichoicesLabels);

    let averages: number[][] = [];
    for (let x = 0; x <= results.columns.length; x++) {
      let colSums = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let y in results.rows) {
        for (let k = 0; k < 13; k++) {
          colSums[k] += results.results[y][x][k];
        }
      }

      // console.log(colSums);
      // console.log(results.rows.length);
      var partial = [];
      for (let k = 0; k < 13; k++) {
        partial.push(Math.round(colSums[k] / results.rows.length));
      }
      averages.push(partial);
    }
    averages.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

    this._researchDetailsScreenController.setData!({
      research,
      results,
      averages,
      cellsIds,
      cellsLabels,
      cellsMultichoicesLabels
    });
    this._researchDetailsScreenController.setAsLoaded!();
  }
}
