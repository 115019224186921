import { injectable } from "inversify";
import BehaviourEditScreenData from "@/adapters/models/screen-behaviour-edit";

type EmptyAction = () => void;
type SetData = (data: BehaviourEditScreenData) => void;
type GetData = () => BehaviourEditScreenData;

@injectable()
export default class BehaviourEditScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
