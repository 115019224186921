import { injectable } from "inversify";
import CorrelationViewScreenData from "@/adapters/models/screen-correlation-view";
import { User } from "@/domain/users/models";

type EmptyAction = () => void;
type SetData = (data: CorrelationViewScreenData) => void;
type GetData = () => CorrelationViewScreenData;
type UsersAction = (users: User[]) => void;

@injectable()
export default class CorrelationViewScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
  reload?: EmptyAction;
  setAsSearchingUser?: EmptyAction;
  setUsersFound?: UsersAction;
}
