import { injectable, inject } from "inversify";
import ReportCreateScreenController from "@/adapters/controllers/screen-report-create";
import { ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";
import { IPlanGateway } from "@/adapters/gateways/plans";

@injectable()
export default class InitReportCreateInteractor {
  _reportCreateScreenController: ReportCreateScreenController;
  _sessionGateway: ISessionGateway;
  _routerController: RouterController;
  _planGateway: IPlanGateway;

  constructor(
    @inject(ReportCreateScreenController)
    reportCreateScreenController: ReportCreateScreenController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("PlanGateway")
    planGateway: IPlanGateway
  ) {
    this._reportCreateScreenController = reportCreateScreenController;
    this._sessionGateway = sessionGateway;
    this._routerController = routerController;
    this._planGateway = planGateway;
  }

  async handle(): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.tokens) {
      this._routerController.openLogin!();
      return;
    }
    this._reportCreateScreenController.setAsLoading!();
    const plansPage = await this._planGateway.list(
      session.tokens!,
      undefined,
      undefined,
      "name",
      1000
    );
    this._reportCreateScreenController.setData!({ plans: plansPage.results });
    this._reportCreateScreenController.setAsLoaded!();
  }
}
