import { injectable } from "inversify";
import CustomReportListScreenData from "@/adapters/models/screen-custom-report-list";

type EmptyAction = () => void;
type SetData = (data: CustomReportListScreenData) => void;
type GetData = () => CustomReportListScreenData;

@injectable()
export default class CustomReportListScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
