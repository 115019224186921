import { injectable, inject } from "inversify";
import ReportEditScreenController from "@/adapters/controllers/screen-report-edit";
import { IReportGateway } from "@/adapters/gateways/reports";
import { RouterController } from "@/adapters/controllers/router";
import { ISessionGateway } from "@/adapters/gateways/users";
import { IPlanGateway } from "@/adapters/gateways/plans";

@injectable()
export default class InitReportEditInteractor {
  _reportEditScreenController: ReportEditScreenController;
  _reportGateway: IReportGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;
  _planGateway: IPlanGateway;

  constructor(
    @inject(ReportEditScreenController)
    reportEditScreenController: ReportEditScreenController,
    @inject("ReportGateway")
    reportGateway: IReportGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway,
    @inject("PlanGateway")
    planGateway: IPlanGateway
  ) {
    this._reportEditScreenController = reportEditScreenController;
    this._reportGateway = reportGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
    this._planGateway = planGateway;
  }

  async handle(id: string): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._reportEditScreenController.setAsLoading!();
    const plansPage = await this._planGateway.list(
      session.tokens!,
      undefined,
      undefined,
      "name",
      1000
    );
    const plans = plansPage.results;
    const report = await this._reportGateway.retrieve(id, session.tokens!);

    for (const plan of plans) {
      for (const reportPlan of report.plans!) {
        if (plan.id == reportPlan.plan) {
          plan.isSelected = true;
          break;
        }
      }
    }

    for (const variant of report.variants!) {
      const genoset = variant.genoset;
      const genotype = variant.genotype;
      if (genoset != null) {
        variant.originType = 1;
        variant.genosetValue = genoset.name;
        variant.genoset = genoset.id;
        variant.genotypeValue = "";
        variant.genotype = null;
      } else if (genotype != null) {
        variant.originType = 2;
        variant.genotypeValue = `${genotype.snp.rsid}(${genotype.alleles})`;
        variant.genotype = genotype.id;
        variant.genosetValue = "";
        variant.genoset = null;
      }
    }

    this._reportEditScreenController.setData!({ report, plans });
    this._reportEditScreenController.setAsLoaded!();
  }
}
