import { injectable } from "inversify";
import PlanCodeCreateScreenData from "@/adapters/models/screen-plan-code-create";

type EmptyAction = () => void;
type SetData = (data: PlanCodeCreateScreenData) => void;
type GetData = () => PlanCodeCreateScreenData;

@injectable()
export default class PlanCodeCreateScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
