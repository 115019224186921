import { injectable, inject } from "inversify";
import UserEditScreenController from "@/adapters/controllers/screen-user-edit";
import { IUserGateway, ISessionGateway } from "@/adapters/gateways/users";
import { RouterController } from "@/adapters/controllers/router";

@injectable()
export default class InitUserEditInteractor {
  _userEditScreenController: UserEditScreenController;
  _userGateway: IUserGateway;
  _routerController: RouterController;
  _sessionGateway: ISessionGateway;

  constructor(
    @inject(UserEditScreenController)
    userEditScreenController: UserEditScreenController,
    @inject("UserGateway")
    userGateway: IUserGateway,
    @inject(RouterController)
    routerController: RouterController,
    @inject("SessionGateway")
    sessionGateway: ISessionGateway
  ) {
    this._userEditScreenController = userEditScreenController;
    this._userGateway = userGateway;
    this._routerController = routerController;
    this._sessionGateway = sessionGateway;
  }

  async handle(id: string): Promise<void> {
    const session = this._sessionGateway.get();
    if (!session || !session.credential) {
      this._routerController.openLogin!();
      return;
    }
    this._userEditScreenController.setAsLoading!();
    const user = await this._userGateway.retrieve(id, session.tokens!);
    this._userEditScreenController.setData!({ user });
    this._userEditScreenController.setAsLoaded!();
  }
}
