import { injectable } from "inversify";
import UserListScreenData from "@/adapters/models/screen-user-list";

type EmptyAction = () => void;
type SetData = (data: UserListScreenData) => void;
type GetData = () => UserListScreenData;

@injectable()
export default class UserListScreenController {
  setAsLoading?: EmptyAction;
  setAsLoaded?: EmptyAction;
  getData?: GetData;
  setData?: SetData;
}
